/* ==========================================================================
   FOOTER
   ========================================================================== */
body {
    footer {
        content-visibility: auto;
        contain-intrinsic-size: 1px 500px;

        &#footer-master {
            padding: 2rem 0 1rem;
            color: var(--color-accent-7);

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                a {
                    color: var(--color-accent-7);
                }

                &.nav {
                    // Lang selector
                    margin: 2rem 0 1rem;

                    li:first-of-type {
                        line-height: 2.5rem;
                    }

                    .dropdown-menu {
                        min-width: unset;
                    }
                }

                &.social-icons.follow {
                    a {
                        @include font-size(30, 4vw, 34);
                    }
                }
            }

            a {
                &.select-country {
                    display: inline-flex;
                    border: 2px solid $light;
                    border-radius: 50px !important;
                    color: $light;
                    margin: 1rem 0;

                    span {
                        margin-right: .5rem;

                        &:last-of-type {
                            margin-right: 0;
                            margin-left: .5rem;
                        }
                    }
                }
            }

            small {
                display: flex;
                align-items: center;
                font-weight: 100;

                i {
                    font-size: 14px;
                    margin: 5px;
                }
            }

            #filagroup {
                display: flex;
                justify-content: center;
                border-top: 1rem solid var(--color-light);
                margin-top: .5rem;
                padding: 1rem 0 0;
            }
        }
    }


    .select-country {
        &-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            padding: 2rem 0;
            background-color: hsla(0, 0%, 0%, .9);
            z-index: 20;

            display: grid;
            display: none;
            grid-template-columns: 1fr;
            grid-template-rows: 60px 1fr;
            gap: 1px 1px;
            grid-template-areas: "select-country--top""select-country--content";
        }

        &--top {
            grid-area: select-country--top;
            display: flex;
            justify-content: flex-end;
            padding-right: 3rem;


            a {
                display: flex;
                align-items: center;
                @include font-size(20, 4vw, 26);
                line-height: 26px;
                color: $light;

                ion-icon {
                    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(100deg) brightness(100%) contrast(100%);
                    @include font-size(20, 4vw, 55);
                    line-height: 26px;
                }

                #{$hover} {
                    text-decoration: none !important;
                }
            }
        }

        &--content {
            grid-area: select-country--content;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;

            ul.nav {
                display: flex;
                flex-direction: column;

                li {
                    margin-bottom: 1rem;
                    display: flex;

                    a {
                        display: inline-flex;
                        flex: 1;
                        color: $light;
                        @include font-size(20, 4vw, 26);

                        #{$hover} {
                            background-color: transparent !important;

                            span:first-of-type {
                                transform: scale(1.2);
                                transition: all 400ms ease;
                            }
                        }

                        span {

                            &:first-of-type {
                                margin-right: 1rem;
                                align-self: flex-start;
                                border: 1px solid $light;
                                transition: all 400ms ease;
                                white-space: break-spaces;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

// MOBILES
@include media-breakpoint-down(sm) {
    body {
        .select-country {
            &--top {}

            &--content {

                ul.nav {

                    li {

                        a {

                            span {
                                &.flag-icon {
                                    flex: 0 0 29px; // make this for mobile only
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// TABLETS ONLY
@include media-breakpoint-only(md) {}

// Tablets & Desktops
@include media-breakpoint-up(md) {
    body {
        .select-country {
            &--top {}

            &--content {

                ul.nav {

                    li {

                        a {

                            span {
                                &.flag-icon {
                                    flex: 0 0 36px; // make this for mobile only
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}