.paintload-action {
    background-color: #000000;
}

.paintload-action-container {
    margin-top: 41px;

    .paintload-action-title {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.48px;
        color: #FFFFFF;
        margin-bottom: 14px;
    }

    .paintload-action-desc>p {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0px;
        color: #FFFFFF;
    }
}

.paintload-action-image {
    margin-top: 11px;
    padding-bottom: 30px;
}

@media (max-width:990px) {
    .paintload-action-arrow {
        position: relative;
        right: -85%;
        top: 30px;
        cursor: pointer;
        z-index: 9;
    }
}

@media (min-width:991px) {
    .paintload-action-container {
        margin-top: 78.5px;

        .paintload-action-title {
            font-size: 30px;
            line-height: 40px;
            letter-spacing: -0.6px;
            margin-bottom: 32px;
        }
    }

    .paintload-action-image {
        margin-top: 78.5px;
        padding-bottom: 68px;
    }

    .paintload-action-arrow {
        position: relative;
        right: -80%;
        top: 30px;
        cursor: pointer;
        z-index: 9;
    }
}