/* ==========================================================================
   SIGNPOST -- STOCKIST BANNER
   ========================================================================== */

body {
    #banner-stockist {
        content-visibility: auto; 
        contain-intrinsic-size: 1px 5000px;
        background: #e4e4e4;
        color: #000000;
        padding: 2rem 0;
        margin: 1rem 0;

        i {
            @include font-size(44, 4vw, 44);
        }

        p {
            @include font-size(16, 4vw, 19);
            margin-bottom: 5px;

            &:first-of-type {
                @include font-size(13, 4vw, 23);
                font-weight: 600;
                text-align: left;
            }
        }

        .col-2 {
            display: flex;
        }

        .btn {
            color: var(--color-dark);
            font-weight: 600;
            align-self: center;
            flex: auto;
        }


        &.search {

            i {
                margin-top: -.5rem;
                @include font-size(60, 4vw, 60);
            }

            p {
                &:last-of-type {
                    @include font-size(13, 4vw, 16);
                }
            }

            form {
                &.search {
                    padding: 0;
                    align-items: center;

                    label {
                        @include font-size(13, 4vw, 16);
                        padding-left: 1rem;
                    }

                    input {
                        flex: 1 1 100%;
                    }

                    .btn {
                        width: 50px !important;
                    }
                }

                &.store {
                    label {
                        flex: 1 1 100%;
                    }

                    a {
                        padding: 8px 7px 3px !important;

                        i {
                            @include font-size(24, 4vw, 24);
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }
}


/* ==========================================================================
   Media Queries
   ========================================================================== */

// MOBILES
@include media-breakpoint-down(sm) {
    body {
        #banner-stockist {
            &.search {
                padding: 3rem 0;

                form {
                    &.search {
                        &.store {
                            label {
                                border: none;
                                @include font-size(16, 4vw, 23);
                            }
                        }

                        .btn {
                            width: 45px !important;
                        }

                        a i,
                        .md {
                            @include font-size(24, 4vw, 24);
                            line-height: 36px;
                        }
                    }
                }
            }
        }
    }
}

// MOBILE & TABLETS
@include media-breakpoint-down(md) {
    body {
        #banner-stockist {
            &.search {
                padding: 3rem 0;

                form {
                    &.search {
                        .btn {
                            width: 48px !important;
                        }
                    }
                }
            }
        }
    }
}

// TABLETS ONLY
@include media-breakpoint-only(md) {}

// Tablets & Desktops
@include media-breakpoint-up(md) {}

// DESKTOPS
@include media-breakpoint-up(lg) {}