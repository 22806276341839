.about-simply-container {
    background-color: #a2a3a2;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 16px;
    padding-right: 16px;
    .about-simply-title {
        .about-title {
            color: #fff;
            font-weight: bold;
            font-size: 48px;
            line-height: 50px;
            letter-spacing: -0.96px;
            margin-top: 40px;
        }
        .about-simply-description-desktop p {
            display: none;
        }
    }
    .about-simply-description-mobile p {
        padding-top: 25px;
        color: #fff;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.48px;
    }
}

.visit-simply {
    .visit-simply-description p {
        margin-top: -50px;
        padding-bottom: 40px;
        line-height: 26px;
    }
}

.arrow-button {
    .about-simply-btn {
        display: flex;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: #0a4b91;
        color: #fff;
        font-size: 20px;
        border-radius: 5px;
        margin-bottom: 40px;
    }
}

.about-simly-section {
    margin-top: 60px;
}

.img-container {
    display: none;
}

.mobile-dots {
    margin-left: -7px;
    min-width: 328px;
}

.visit-simply {
    padding-right: 32px;
    padding-left: 32px;
}

#about-simply .about-simply-btn:hover {
    color: #fff !important;
    background-color: #007a39 !important;
}

@media (max-width:767px) {
    .simply-image {
        display: none;
    }
    .about-simply-container {
        margin-bottom: 110px;
    }
    // .about-simply-description-mobile {
    //     margin-bottom: -160px;
    // }
    .simply-image {
        display: none;
    }
    // .mobile-dots {
    //     display: none;
    // }
}

@media (min-width:992px) {
    .about-simply-container {
        padding-top: 125px;
        .about-simply-title {
            .about-simply-description-desktop p {
                font-size: 35px;
                color: #fff;
                margin-left: 60px;
                display: block;
                letter-spacing: -0.7px;
                line-height: 40px;
                margin-top: 20px;
            }
            // margin-top: 25px;
            .about-title {
                margin-left: 62px;
                font-size: 60px;
                letter-spacing: -1.2px;
                line-height: 60px;
            }
        }
        .about-simply-description-mobile p {
            display: none;
        }
    }
    .visit-simply {
        .visit-simply-description p {
            max-width: 477px;
            line-height: 26px;
            margin-top: 80px;
        }
    }
    .arrow-button {
        .about-simply-btn {
            max-width: 319px !important;
            font-size: 23px;
            margin-bottom: 80px;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 18px;
            padding-right: 18px;
        }
    }
    .arrow-image {
        margin-left: 40px;
    }
    .draw-dots {
        .black-dots {
            position: relative;
            bottom: 177px;
            left: -50px;
        }
    }
    .simply-image {
        max-width: 50%;
        display: none;
    }
    .mobile-dots {
        display: none;
    }
    .img-container {
        display: block;
    }
}