/* ==========================================================================
   STORE LOCATOR STYLES
   ========================================================================== */

form.search {
    &.store {
        label {
            border-left: 1px solid var(--color-accent-7);
            padding-left: 1.75rem;
        }

        .btn {
            margin-left: 15px !important;
            padding-bottom: 3px;
            padding-left: 7px !important;
        }
    }
}


div.store-locator {
    form.search {
        padding: 0;
        margin-top: 1rem;

        &.store,
        &.current-location {
            label {
                border-left: none;
                padding-left: 0;
                padding-right: 1.75rem;
                color: var(--color-dark);
            }

            input[type=search] {
                border: 1px solid var(--color-dark);
                margin: 0;
            }
        }

        &.store {
            border-right: 1px solid var(--color-dark);
        }
    }

    #map {
        margin-top: 2rem;
    }
}

.retailers-online {

    h2 {
        font-size: 26px;
        font-weight: 300;
        margin: 50px 15px 0 15px;
    }

    ul {
        margin: 20px 0;
        padding: 0;
        list-style: none !important;
        column-count: 4;
        column-gap: 30px;

        li {
            &::before {
                content: '' !important;
                padding-left: 5px !important;
            }

            a {
                color: var(--color-accent-8);
            }
        }
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media (max-width: 575.98px) {
    .retailers-online {
        ul {
            column-count: 1;

            li {
                margin: 20px 0;
            }
        }
    }
}

@media (max-width: 768.98px) {
    body {
        div.store-locator form.search.store {
            border-right: none;
        }
    }

    .retailers-online {
        ul {
            column-count: 2;

            li {
                margin: 20px 0;
            }
        }
    }
}

@media (max-width: 768.98px) {}