/* ==========================================================================
   FEATURED ARTISTS STYLES
   ========================================================================== */
.featured-artists {
    ul {
        padding: 0;
        margin: 0 0 2rem -10px;

        li {

            column-rule: outset;

            a {
                padding: 0 10px;
                @include font-size(10, 4vw, 16);
                line-height: 14px;
                border-right: 1px solid var(--color-dark);
                color: var(--color-dark);
                margin-bottom: 15px;
            }

            &:last-of-type a {
                border: none;
            }
        }
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media (max-width: 575.98px) {

    .featured-artists ul li a {
        margin-bottom: 10px;
    }

}