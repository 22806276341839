/* ==========================================================================
   FONTS & SIZES
   ========================================================================== */

@import url('https://fonts.googleapis.com/css?family=Hind:300,400,600,700|Open+Sans&display=swap');

.lucky-fellas {
    font-family: 'LuckyFellas', cursive, serif;
    font-weight: normal;
    font-style: normal;
}

$font-family-sans-serif: 'Hind', 'Open Sans', Arial, sans-serif;

// @mixin font-size($sizeValue, $important:"") {
//     // PX value passed & if we need to add !important
//     $baseSizeValue: 0; // Set default
//     $fontSize: ($baseSizeValue + $sizeValue); // Calc font size as px
//     $remValue: ($sizeValue / 10); // Calc font sizeas as rem
//     $lineHieght: $fontSize + 8; // Calc line height
//     font-size: $fontSize + px + unquote($important); // Print font size in px (fallback)
//     font-size: $remValue + rem + unquote($important); // Print font size in rem
//     line-height: $lineHieght + px + unquote($important); // Print line height
// }

@mixin font-size($minSizeValue, $middleValue, $maxSizeValue, $addToLineHight: 4, $important:"") {
    // PX value passed & if we need to add !important
    $baseSizeValue: 0; // Set default
    $minFontSize: ($baseSizeValue + $minSizeValue); // Calc min font size as px
    $maxFontSize: ($baseSizeValue + $maxSizeValue); // Calc max font size as px

    $minRemValue: ($minSizeValue / 10); // Calc font sizeas as rem
    //s$addToLineHight: 4;
    $lineHieght: $minSizeValue + 14; // Calc line height

    font-size: $maxFontSize + px + unquote($important); // Print font size in px (fallback)
    //font-size: $minRemValue + rem + unquote($important); // Print font size in rem
    font-size: clamp($minFontSize + px, $middleValue, $maxFontSize + px) + unquote($important);

    //line-height: $lineHieght + px + unquote($important); // Print line height
    line-height: clamp(($minFontSize + $addToLineHight) + px, $middleValue, ($maxFontSize + $addToLineHight) + px)+unquote($important); // Print line height
}


// Line-height Crop — a simple CSS formula to remove top space from your text
// https://medium.com/codyhouse/line-height-crop-a-simple-css-formula-to-remove-top-space-from-your-text-9c3de06d7c6f
@mixin lhCrop($line-height) {
    &::before {
        content: '';
        display: block;
        height: 0;
        width: 0;
        margin-top: calc((1 - #{$line-height}) * 0.5em);
    }
}

@mixin extra-bold($style: normal, $color: "") {
    font-family: $font-family-sans-serif;
    font-size-adjust: 0.49;
    font-weight: 900;
    font-style: $style;
    text-shadow: #{$color} 0px 2px,
    2px 0px,
    2px 2px;
}

@mixin bold($style: normal) {
    font-family: $font-family-sans-serif;
    font-size-adjust: 0.49;
    font-weight: 700;
    font-style: $style;
}

@mixin regular($style: normal) {
    font-family: $font-family-sans-serif;
    font-size-adjust: 0.48;
    font-weight: 400;
    font-style: $style;
}

@mixin light($style: normal) {
    font-family: $font-family-sans-serif;
    font-size-adjust: 0.44;
    font-weight: 300;
    font-style: $style;
}

// html {
//     font-size: 62.5%; /* Sets up the Base 10 stuff */
// }

body {
    //@include regular(light);
    font-family: $font-family-sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .font-weight--extra-bold {
        @include extra-bold;
    }

    strong,
    .font-weight-bold {
        @include bold(normal);
    }

    .font-weight-normal {
        @include regular(normal);
    }

    .font-weight-light {
        @include light(normal);
    }


    .text {
        &--color {

            &--light,
            &--white {
                color: var(--color-light);
            }

            &--dark,
            &--black {
                color: var(--color-dark);
                text-shadow: none;
            }

            &--green {
                color: var(--color-accent-8);
            }

            &--grey {
                color: var(--color-accent-6);
            }
        }

        &--effect {
            &--shadow {
                text-shadow: 0px 0px 6px var(--color-dark);
            }
        }
    }


    p,
    li {
        widows: 3;
        orphans: 3;
    }

    p {}

    a {
        cursor: pointer;
    }

    ul {}

    ol {}

    // makes sure these words are on the same line.
    .no-wrapped-text {
        display: inline-block;
        font-style: normal;
    }

    .bg-dynamo,
    .dymo {

        span.dymo-text {
            display: inline;
            padding: 8px 5px;
            margin-left: 10px;
            box-decoration-break: clone;
            background-color: var(--color-dark); //Default
            @extend .text-light; //Default
            box-shadow: 10px 0 0 var(--color-dark), -10px 0 0 var(--color-dark); //Default
            line-height: 2.1;
        }

        &.dymo-light {
            span.dymo-text {
                background-color: var(--color-light);
                @extend .text-dark;
                box-shadow: 10px 0 0 var(--color-light), -10px 0 0 var(--color-light);
            }
        }

        &.dymo-dark {
            span.dymo-text {
                box-shadow: 10px 0 0 var(--color-dark), -10px 0 0 var(--color-dark);
            }
        }

        &.dymo-opaque {
            span.dymo-text {
                $opaque: rgba(var(--color-dark), .6);
                background-color: $opaque;
                box-shadow: 10px 0 0 $opaque, -10px 0 0 $opaque;
            }
        }

        &.dymo-shadow {
            span.dymo-shadow--wrapper,
            &.btn {
                box-shadow: 5px 0px 13px 10px rgba(var(--color-dark), .4);
            }

            > span {
                display: inline;
            }
        }
    }

    p.dymo {
        span.dymo-text {
            line-height: 2.8;
        }
    }


    .main-content {

        .title- {
            &section {
                @include font-size(15, 4vw, 18);
                font-weight: 300;
                margin-bottom: 2rem;
            }

            &page {
                font-size: calc(1.325rem + .9vw);
                margin-bottom: 2rem;
            }
        }

        // remove the default list style and fake it on the span within the li
        // so the colour can be set via WordPress
        ul:not(.navigation):not(.nav):not(.slides):not(.flex-direction-nav):not(.pagination):not(.social-share-list):not(.tags):not(.key):not(.view-toggle):not(.timeline--list):not(.hazards):not(.hazard-codes):not(.hazard-icon-titles) {
            list-style: none;
            padding: 0;

            li:not(.list-item) {
                @include font-size(10, 4vw, 16);
                margin-bottom: 10px;
                display: flex;

                &:last-of-type {
                    margin: 0
                }

                &:before {
                    content: '•';
                    padding-right: 10px;
                    padding-left: 0;
                }

            }
        }

        // The same as above, but use counter() to count the number on the list items
        ol:not(.carousel-indicators):not(.breadcrumb) {
            list-style: none;
            padding: 0;
            counter-reset: section;

            li {
                @include font-size(10, 4vw, 16);
                margin-bottom: 5px;

                counter-increment: section;

                &:last-of-type {
                    margin: 0
                }

                &:before {
                    content: counter(section)".";
                    padding-right: 10px;
                    padding-left: 0;
                }

            }
        }
    }
}