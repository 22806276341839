.collaborations {
    background-color: #fff;
    margin-top: 50px;
    color: #000;

    .collaborations-container {
        .projects-title {
            font-size: 40px;
            line-height: 40px;
            letter-spacing: -.8px;
            margin-top: 25px;
            position: relative;
            z-index: 1;

            @media (max-width: 990px) {
                margin-left: -15px;
            }
        }

        .collaborations-desc>p {
            margin-top: 15px;
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0;
        }

        .collaborations-btn-desk {
            display: none;
        }
    }

    .collaborations-images-container {
        .collaborations-img-title {
            font-size: 20px;
            margin-top: 10px;
            line-height: 30px;
            letter-spacing: -0.4px;
            text-align: center;
            margin-bottom: 24px;

            @media (max-width: 767px) {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        .collaborations-btn-mob {
            display: block;
            background-color: #000;
            padding: 0px 60px;
            max-width: max-content;
            color: #FFF !important;
            font-size: 20px;
            line-height: 45px;
            letter-spacing: -0.4px;
            margin: auto;
            margin-top: 30px;
            margin-bottom: 50px;
        }
    }

    .paint-load {
        display: none;
    }

    .paintload-page .collaborations-btn-desk:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
        color: #fff !important;
    }

    @media (max-width:767px) {
        .collaborations-img {
            width: 100%;
        }
    }

    @media (max-width:990px) {
        .collaborations-arrow {
            position: relative;
            right: -85%;
            top: 0;
            z-index: 9;
            cursor: pointer;
        }

        .projects-title {
            left: 10px !important;
        }

        .collaborations-hero-img {
            margin-bottom: 25px;
        }
    }

    @media (min-width:991px) {
        .collaborations {
            position: relative;
            margin-top: 40px;

            .paint-load {
                position: absolute;
                top: 41%;
                display: block;
            }
        }

        .collaborations-container {

            .collaborations-btn-desk,
            .collaborations-btn-mob {
                display: block;
                margin-top: 30px;
                background-color: #000;
                padding: 0px 60px;
                margin-bottom: 36px;
                max-width: max-content;
                color: #FFF !important;
                font-size: 20px;
                line-height: 45px;
                letter-spacing: -0.4px;
                transition: all ease 500ms;

                &:hover {
                    background-color: #006E36;
                    color: #fff !important;
                }
            }

        }

        .rotate-image {
            position: relative;
        }

        // .rotate-image::before {
        //     display: block;
        //     background-color: #00BEA8;
        //     content: "";
        //     width: 350px;
        //     height: 181px;
        // }
        .collaborations-images-container .col-lg-6.col-12:nth-child(1) .rotate-images::before {
            display: block;
            background-color: #00ff58;
            content: "";
            width: 350px;
            height: 181px;
        }

        .collaborations-images-container .col-lg-6.col-12:nth-child(2) .rotate-images::before {
            display: block;
            background-color: #FF0089;
            content: "";
            width: 350px;
            height: 181px;
        }

        .collaborations-images-container .col-lg-6.col-12:nth-child(3) .rotate-images::before {
            display: block;
            background-color: #FF0089;
            content: "";
            width: 350px;
            height: 181px;
        }

        .collaborations-images-container .col-lg-6.col-12:nth-child(4) .rotate-images::before {
            display: block;
            background-color: #006DD9;
            content: "";
            width: 350px;
            height: 181px;
        }

        .collaborations-images-container .col-lg-6.col-12:nth-child(5) .rotate-images::before {
            display: block;
            background-color: #00BEA8;
            content: "";
            width: 350px;
            height: 181px;
        }

        .collaborations-images-container .col-lg-6.col-12:nth-child(6) .rotate-images::before {
            display: block;
            background-color: #FF5800;
            content: "";
            width: 350px;
            height: 181px;
        }

        .collaborations-img {
            position: absolute;
            top: 0;

        }

        .rotate-images:hover::before {
            transform: rotate(5deg);
        }

        .collaborations .container {
            padding-top: 82px;
        }

        .collaborations-hero-img {
            position: relative;
        }

        .collaborations-top-image {
            position: absolute;
            right: 0;
        }

        .collaborations-images-container {
            margin-top: 25px;
            padding-bottom: 45px;

            .collaborations-btn-mob {
                display: none;
            }
        }

        .collaborations-img:hover {
            transform: rotate(-3deg);
        }

        .collaborations-arrow {
            position: relative;
            right: -65%;
            top: 30px;
            z-index: 9;
            cursor: pointer;
        }
    }
}



body a:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
    color: unset !important;
    text-decoration: unset !important;
}

body .collaborations-btn-desk:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
    color: #fff !important;
}

.paintload-page .collaborations-btn-desk:hover {
    background-color: #FF0089 !important;
    color: #fff !important;
    transform: rotate(2deg);
}