/* ==========================================================================
   RESULTS STYLES
   ========================================================================== */

//    body {
//      background: #fff;
//      display: flex;
//      align-items: center;
//      justify-content: center;
//    }


.spinner-container {
    display: none;

    &.active {
        display: block;
        position: absolute;
        z-index: 1;
        height: calc(100% - 150px);
        width: 100%;
        background-color: rgba(255, 255, 255, 0.7);
    }


    .spinner {
        width: 100px;
        height: 100px;
        animation: spin 5s linear infinite;
        background: var(--color-light);
        border-radius: 300px;

        position: sticky;
        top: 10rem;
        left: 58%;
        margin-top: 2rem;

        span {
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            mix-blend-mode: color;
            z-index: 10;

            &:after {
                content: "";
                color: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 100%;
            }

            &:nth-child(1) {
                animation: slide_horiz_neg 4s linear alternate infinite;

                &:after {
                    background-color: var(--color-accent-1); // black
                }
            }

            &:nth-child(2) {
                animation: slide_vert_neg 3s linear alternate infinite;

                &:after {
                    background-color: var(--color-accent-8); // green
                }
            }

            &:nth-child(3) {
                animation: slide_horiz_pos 4.5s linear alternate infinite;

                &:after {
                    background-color: var(--color-accent-1); // black

                }
            }

            &:nth-child(4) {
                animation: slide_vert_pos 6.5s linear alternate infinite;

                &:after {
                    background-color: var(--color-accent-8); // green
                }
            }

            &:nth-child(5) {
                animation: slide_vert_pos 6.5s linear alternate infinite;

                &:after {
                    background-color: var(--color-accent-1); // black
                }
            }
        }
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes slide_vert_pos {
        0% {
            transform: translateY(3%) scale(1);
        }

        100% {
            transform: translateY(5%) scale(1.05);
        }
    }

    @keyframes slide_vert_neg {
        0% {
            transform: translateY(-5%) scale(1);
        }

        100% {
            transform: translateY(-3%) scale(1.05);
        }
    }

    @keyframes slide_horiz_pos {
        0% {
            transform: translateX(3%) scale(1);
        }

        100% {
            transform: translateX(5%) scale(1.05);
        }
    }

    @keyframes slide_horiz_neg {
        0% {
            transform: translateX(-5%) scale(1);
        }

        100% {
            transform: translateX(-3%) scale(1.05);
        }
    }
}








.results-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .results-intro {
        background: var(--color-accent-5);
        margin: -3rem 0 3rem;
        padding: 3rem 0;
    }

    .results-filter-container form.search {
        margin-bottom: 2rem;
        padding: 0;

        label {
            display: none;
        }

        input {
            border: 1px solid var(--color-dark);
            margin-left: 0;
        }

        .btn {
            color: var(--color-light) !important;
            background: var(--color-accent-1--lighten-p50);

            #{$hover} {
                background: var(--color-accent-1--lighten-p75);
            }
        }
    }

    #results-container {
        padding-top: 3rem;
        padding-bottom: 3rem;

        .results-filter-container {

            .filter-title {
                @include font-size(12, 4vw, 16);
                font-weight: 500;
                text-align: left;
            }

            p {
                text-align: right;

                a {
                    @include font-size(10, 4vw, 16);
                    font-weight: 300;
                    color: var(--color-dark);

                }
            }

            .filter {
                border-top: 2px solid var(--color-accent-5);

                .filter-section-toggle {
                    width: 100%;
                    @include font-size(14, 4vw, 24);
                    font-weight: 500;
                    text-align: left;
                    background: none;
                    border: none;
                    margin: 15px 0;
                    padding: 0;
                    display: flex;

                    span {
                        flex: 1 1 auto;
                    }

                    i {
                        width: 34px;
                    }

                    &.collapsed {
                        i {
                            transform: rotate(180deg);
                            text-align: right;
                        }
                    }
                }

                .form-check-inline {
                    width: 100%;
                    padding: 5px 10px;
                    background-color: var(--color-accent-5);
                    margin-bottom: 5px;
                    border-radius: 5px;

                    label {
                        flex: 1 1 auto;
                        cursor: pointer;
                    }
                }
            }
        }

        .results-list-header,
        .results-list-footer {
            display: flex;

            .results-list-title {
                flex: 1 1 auto;
                @include font-size(18, 4vw, 22);
                font-weight: 500;
            }

            .pagination {

                .page-item {
                    a {
                        margin: 0 0.2rem;
                        background: var(--color-accent-5);
                        color: var(--color-dark);
                        border: none;
                        border-radius: 0;
                    }

                    #{$hover} {
                        a {
                            background: var(--color-dark);
                            color: var(--color-accent-7) !important;
                            text-decoration: none !important;
                        }
                    }
                }

                .prev-next {
                    padding-right: 0.3rem;
                    padding-bottom: 0;
                    padding-left: 0.3rem;

                    i {
                        line-height: 0.95;
                    }
                }
            }

            .dropdown-toggle {
                display: flex;
                background: var(--color-accent-5);
                color: var(--color-dark);
                border: none;
                width: 100%;

                &::after {
                    display: none;
                }
            }
        }

        .results-list-footer {
            border-top: 2px solid var(--color-accent-5);
            margin-top: 2rem;
            padding-top: 2rem;
        }

        .view-toggle {

            li {
                display: inline-flex;
                margin-left: 10px;
                @include font-size(12, 4vw, 16);
                cursor: pointer;
                opacity: 0.3;



                &.active {
                    opacity: 1;
                    @extend .animate;
                }

                i {
                    margin-left: 5px;
                    @include font-size(30, 4vw, 34);
                    line-height: 0.65;
                }
            }
        }

        #results {
            &.view--grid {

                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;

                .results-list-header {
                    margin-bottom: 5px;
                    border-bottom: 2px solid var(--color-accent-5);

                }

                .results-list-footer {}

                .results-list-header,
                .results-list-footer {
                    flex: 0 0 100%;
                }

                .result-item {
                    display: flex;
                    flex-direction: column;
                    width: 33.3%;
                    padding: 1%;
                    margin-top: 0;
                    border-top: none;
                    border-bottom: 1px solid var(--color-accent-5);

                    .result-item-data {

                        .result-item-title {
                            padding: 0 2%;

                            h2 {
                                @include font-size(13, 4vw, 23);
                                text-align: center;
                                margin: 1rem 0;
                            }
                        }
                    }

                    .result-item-description,
                    .result-item-tags {
                        display: none;
                    }

                    &:nth-child(-n+4) {
                        margin-top: 15px;
                    }

                    &:nth-child(3n+3) {
                        border-right: 1px solid var(--color-accent-5);
                        border-left: 1px solid var(--color-accent-5);
                    }
                }
            }
        }

        .result-item {
            display: grid;
            grid-template-columns: 184px 1fr;
            grid-template-rows: 1fr;
            grid-template-areas: "result-item-image result-item-data";
            border-top: 2px solid var(--color-accent-5);
            padding: 2rem 0;
            color: var(--color-dark);

            #{$hover} {
                text-decoration: none !important;

                img {
                    transform: scale(1.07);
                }

                .result-item-data {

                    .result-item-title h2 {
                        color: var(--color-accent-8);
                    }

                    .result-item-description,
                    .result-item-tags {
                        color: var(--color-dark);
                    }
                }
            }

            .result-item-image {
                grid-area: result-item-image;

                img {
                    will-change: transform;
                    transition: all 1s !important;
                }
            }

            .result-item-data {
                grid-area: result-item-data;

                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: max-content 1fr;
                grid-template-areas:
                    "result-item-title"
                    "result-item-description"
                    "result-item-tags";

                .result-item-title {
                    grid-area: result-item-title;
                    padding-left: 15px;

                    h2 {
                        color: var(--color-dark);
                    }
                }

                .result-item-description {
                    grid-area: result-item-description;
                    padding-left: 15px;
                    padding-top: 15px;

                    h2 {
                        @include font-size(10, 4vw, 16);
                        @include bold(normal);
                    }

                    p {
                        @include font-size(10, 4vw, 16);
                    }
                }

                .result-item-tags {
                    grid-area: result-item-tags;
                    padding-left: 15px;

                    display: none;

                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        // column-count: 2;
                        display: flex;

                        li {

                            &.level,
                            &.base {
                                padding-left: 50px;
                                background-position: center left;
                                background-repeat: no-repeat;
                                background-size: 40px;
                                min-height: 40px;
                                display: flex;
                            }

                            &.level {
                                background-image: url('../images/icon-level@2x.png');
                                display: flex;
                                flex-flow: column;
                                flex: 1 1 55%;
                            }

                            &.base {
                                background-image: url('../images/icon-base@2x.png');
                                flex: 1 1 25%;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

@media (max-width: 575.98px) {

    .results-list {
        #results-container {

            .view-toggle {
                padding-left: 0;
            }

            #results {
                &.view--grid {

                    .result-item {
                        width: 50%;

                        .result-item-data {
                            grid-template-rows: min-content;
                        }

                        &:nth-child(-n+4) {
                            margin-top: 0;
                        }

                        &:nth-child(3n+3) {
                            border-right: none;
                            border-left: none;
                        }

                        &:nth-child(2n+2) {
                            border-right: 1px solid var(--color-accent-5);
                        }
                    }


                }
            }

            .results-list-header {
                margin-top: 3rem;


            }

            .results-list-header,
            .results-list-footer {
                flex-direction: column;

                .results-list-title {
                    margin-bottom: 1rem;
                }

                nav {
                    display: flex;
                    width: 100%;

                    .pagination {
                        width: 100%;

                        a {
                            text-align: center;
                        }
                    }
                }
            }

            .result-item {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr min-content;
                grid-template-areas: "result-item-image""result-item-data";

                .result-item-data {

                    grid-template-rows: 80px min-content;

                    .result-item-title,
                    .result-item-description {
                        padding-top: 15px;
                        padding-left: 0;
                    }
                }
            }
        }

    }
}