/* ==========================================================================
   FORMS
   ========================================================================== */
body {

   .mc_embed_signup_ajax {

      small {
         display: flex;
         align-items: center;
         font-weight: 100;

         i {
            font-size: 14px;
            margin: 5px;
         }
      }

      input {
         border-radius: 0 !important;

         &.required {

            &.error {
               border-bottom: 1px solid #eb2344;
            }
         }

         &[type=submit] {
            margin: 0 !important;
            width: 100% !important;
         }
      }
   }



   /* Show focus state for power users that use 'TAB' to switch fields */

   .error-message {
      display: none;
      color: #eb2344;
      // text-align: center;
      font-size: .8em;
   }

   .success-message {
      display: none;
      // text-align: center;

      >div {
         width: 100%;
         opacity: 0;
         animation: reveal 1s 1s forwards;
         //  text-align: center;
      }
   }

   /* Appear animation for success message */
   @keyframes reveal {

      0%,
      75% {
         opacity: 0;
      }

      100% {
         opacity: 1;
      }
   }

   /* 'SUCCESS!' check animation */

   svg {
      &.checkmark {
         width: 75px;
         height: 75px;
         border-radius: 50%;
         stroke-width: 4;
         stroke: var(--color-accent-8);
         stroke-miterlimit: 10;
         box-shadow: inset 0px 0px 0px #7ac142;
         transition: 1s all ease;

         &.active {
            animation: moveupwards 1s 1s forwards;
         }
      }

      > {
         .checkmark-circle {
            stroke-dasharray: 166;
            stroke-dashoffset: 166;
            stroke-width: 3;
            stroke-miterlimit: 10;
            stroke: var(--color-accent-8);
            fill: none;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
         }

         .checkmark-check {
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-width: 3;
            stroke-dashoffset: 48;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
         }
      }
   }

   /*
        Trigger animation we add 'active' class via JS
    */

   /*
      'Move upwards' animation below, no need for absolutely positioned
      elements or transform: translate. We'll create the subtle 'moving up'
      effect by playing with margins and size. Responsive friendly!
    */
   @keyframes moveupwards {

      0%,
      75% {
         width: 75px;
         height: 75px;
         margin: 2em auto;
      }

      100% {
         width: 50px;
         height: 50px;
         margin: 1em auto;
      }
   }

   /*
        Paths inside SVG. Each path will be animated using stroke property and 'stroke' animation
    */

   @keyframes stroke {
      100% {
         stroke-dashoffset: 0;
      }
   }

   /*
        Sorry IE and Edge users, no SVG animations supported for you, so we'll hide our SVG to prevent empty space gap
    */
   @media screen and (-ms-high-contrast: active),
   (-ms-high-contrast: none) {
      .success-message svg {
         display: none;
      }
   }

   @supports (-ms-ime-align: auto) {
      .success-message svg {
         display: none;
      }
   }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */