.simply-project-container {
    display: flex;
    flex-wrap: wrap;
    background-color: #a2a3a2;
    margin-right: 15px;
    margin-left: 15px;
    border-radius: 7px;
    padding: 41px 0px;
    margin-bottom: 34px;
    margin-top: 43px;
}

.simply-project-title {
    font-size: 48px;
    color: #fff;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: -0.96px;
    margin-top: 30px;
}

.simply-project-sub-title-container {
    .simply-project-sub-title {
        font-size: 24px;
        color: #fff;
        line-height: 30px;
        letter-spacing: -0.48px;
    }
    .simply-project-description {
        color: #fff;
        line-height: 26px;
    }
}

.select-project {
    .projects {
        border: #F5F5F5 !important;
        padding: 10px;
        min-width: 345px;
        border-radius: 6px;
        background-color: #f5f5f5 !important;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        appearance: none;
    }
    &:after {
        position: absolute;
        top: 16px;
        border: solid rgba(0,0,0,.30196078431372547);
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 5px;
        content: "";
        transform: rotate(45deg);
        left: 89%;
    }
}

.select-project-container {
    .select-project-header {
        .select-project-title {
            font-size: 22px;
            color: #030209;
            margin-bottom: 20px;
            letter-spacing: -0.48px;
        }
    }
}

.card-container {
    .card-title {
        font-size: 25px;
        color: #000000;
        margin-top: 17px;
        line-height: 30px;
    }
    .card-img-container {
        height: 292px;
        overflow: hidden;
    }
    .card-image {
        margin-top: 32px;
        width: 100%;
    }
    .card-description {
        color: #000;
        font-size: 17px;
        letter-spacing: -0.36px;
        line-height: 26px;
    }
    .card-button {
        padding: 7px;
        border-radius: 4px;
        display: inline-block;
        color: #fff;
        width: 100%;
        text-align: center;
        font-size: 18px;
        line-height: 26px;
    }
}

#simply-project .projects-learnmore:hover {
    color: #fff !important;
    background-color: #007a39 !important;
}

.project-all-project {
    background-color: #696969 !important;
}

.project-acrylic {
    background-color: #f08a1c !important;
}

.project-watercolour {
    background-color: #02adef !important;
}

.project-gouache {
    background-color: #0a4b91 !important;
}

.project-oil {
    background-color: #d92424 !important;
}

.project-drawing {
    background-color: #5b3d82 !important;
}

.projects-learnmore {
    margin-top: 50px;
    padding: 8px 74px;
    background-color: #A2A3A2;
    font-size: 24px;
    line-height: 26px;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}

#projects_search .card-button:hover {
    color: #fff !important;
    background-color: #007a39 !important;
}
// .select-project.rotate::after {
// 	transform: rotate(-135deg);
// 	margin-top: 5px;
// }
@media (max-width:767px) {
    .select-project-container {
        margin-top: 55.6px;
    }
    .simply-title {
        font-size: 36px;
        line-height: 50px;
        letter-spacing: -0.72px;
        font-weight: 700;
    }
}

@media (min-width:992px) {
    .simply-title {
        font-size: 40px;
        color: #000;
        font-weight: 700;
        line-height: 50px;
        // margin-top: 30px;
    }
    .projects-section {
        position: relative;
    }
    .down-arrow-container {
        display: flex;
        justify-content: right;
        align-items: end;
    }
    .select-project {
        margin-right: -80px;
        .projects {
            position: relative;
            min-width: 328px;
            margin-top: 20px;
            appearance: none;
        }
        // &:after {
        //     position: absolute;
        //     top: 35px;
        //     border: solid rgba(0,0,0,.30196078431372547);
        //     border-width: 0 3px 3px 0;
        //     display: inline-block;
        //     padding: 5px;
        //     content: "";
        //     transform: rotate(45deg);
        //     left: 40%;
        // }
    }
    .select-project-container {
        .select-project-header {
            .select-project-title {
                font-size: 33px;
                color: #030209;
                margin-bottom: 20px;
                letter-spacing: -0.66px;
            }
        }
        margin-top: 48px;
        display: flex;
        align-items: center;
        margin-top: 100px;
    }
    .card-container {
        .card-button {
            padding: 7px 24px;
            width: auto;
        }
    }
    .simply-project-container {
        max-width: 888px;
        margin-left: -35px;
        margin-top: 131px;
        position: relative;
        z-index: 2;
        .simply-project-header {
            padding-left: 49px;
        }
        .simply-project-title {
            font-size: 60px;
            letter-spacing: -1.2px;
            margin-top: 20px;
            line-height: 60px;
        }
    }
    .simply-project-sub-title-container {
        margin-left: 20px;
        .simply-project-sub-title {
            font-size: 35px;
            letter-spacing: -0.7px;
            line-height: 40px;
        }
    }
    .left-img {
        position: absolute;
        top: -200px;
        left: 0px;
    }
    #projects_search {
        width: 100%;
    }
    .projects-learnmore {
        margin-top: 90px;
    }
    .card-container {
        position: relative;
        margin-bottom: 64px;
    }
    .card-button {
        position: absolute;
        bottom: -50px;
    }
}