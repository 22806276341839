#new-banner-stockist {
	padding-bottom: 10px;
}
.left-banner {
	background-color: #E4E4E4;
	padding: 46px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.left-banner i {
	font-size: clamp(60px,4vw,60px);
	line-height: clamp(64px,4vw,64px);
	color: black !important;
	margin-top: -.5rem;
}
.banner-text {
	text-align: left;
	font: normal 600 normal 23px/27px Hind;
	letter-spacing: -0.46px;
	color: #000000; 
	opacity: 1;
	//max-width: 240px;
    text-align: center;
    padding-top: 15px; 
}
a.left-banner-link {
	padding: 15px 50px;
	background-color: #007C3A;
	font-size: 15px;
	color: #FFF;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 50px;
    margin-top: 5px;
    min-width: 177px !important;
}
.right-banner {
	background-color: #E4E4E4;
	padding: 46px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}
.right-banner i {
	font-size: clamp(60px,4vw,60px);
	line-height: clamp(64px,4vw,64px);
	color: black !important;
	margin-top: -.5rem;
}
a.right-banner-link {
	padding: 15px 33px;
	background-color: #007C3A;
	font-size: 15px;
	color: #FFF;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 50px;
    margin-top: 5px;
    min-width: 177px !important;
}
.right-banner .banner-text {
    //max-width: 270px;
}
@media (min-width:991px){
    .banner-link {
        margin-left: 230px;
        padding-top: 0px;
    }
    .old-banner {
        margin-left: 15px;
    }
    .left-banner {
        padding: 10px 40px 10px 85px;
        display: flex;
        flex-direction: row;
        max-width: 49%;
        margin-right: 10px;
        
    }
    .right-banner {
        padding: 10px 40px 10px 85px;
        display: flex;
        flex-direction: row;
        max-width: 50%;
        margin-top: 0px;
    }
    a.left-banner-link {
        padding: 0px 49px;
        margin-left: 85px;
    }
    .right-banner-link {
        padding: 0px 35px;
        margin-left: 85px;
    }
    .banner-text {
        padding-left: 34.5px;
        text-align: left;
        letter-spacing: 0px;
    }
    
}

@media (min-width:768px) and (max-width:990px) {
    .right-banner {
        margin-top: 0 !important;
    }
    .image-wrap  {
        text-align: center !important;
    }
    .rotate-images {
        text-align: center !important;
    }
}

@media (max-width:990px) {
    .old-banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
    }
    .banners-content p:first-child {
        font: normal normal 600 23px/31px Hind !important;
        letter-spacing: -.46px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .banner-link {
        margin-left: 0px;
        padding-top: 15px;
    }
}