/* ==========================================================================
   BRAND STYLES
   ========================================================================== */

body .main-content .brand-page {
    .block-row {
        border: 10px solid var(--color-light);

        .text {

            border-right: 10px solid var(--color-light);

            h2 {
                font-weight: 800;
                margin-bottom: 3rem;
            }

            ul {
                li {
                    font-size: 20px !important;
                    margin-bottom: 20px !important;
                }
            }
        }

        .image {
            background-size: cover;
        }
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media (max-width: 575.98px) {
    body .main-content .brand-page {
        .block-row {
            border: none;

            .text {
                min-height: 646px;
                border-right: none;
            }

            .image {
                min-height: 646px;
            }
        }
    }
}