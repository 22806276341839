.custom-select-wrapper {
    position: relative;
    font-family: Arial;
}
.custom-select-wrapper select {
	opacity: 0;
	width: 0;
	height: 0;
	position: fixed;
	top: -300px;
	left: -200px;
}
.select-selected {
    background-color: #f5f5f5;
}
.select-selected::after {
    color: #000 !important;
    position: absolute;
    border: solid rgba(0, 0, 0, .30196078431372547);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
    content: "";
    transform: rotate(45deg);
    left: 90%;
    top: 12px !important;
    transition: all 500ms ease-in;
}
.select-selected.select-arrow-active::after {
    transform: rotate(-135deg);
    margin-top: 5px;
}
.select-items div,
.select-selected {
    color: #000;
    padding: 8px 16px;
    cursor: pointer;
}
.select-items {
    position: absolute;
    background-color: #f5f5f5;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    width: 91%;
    margin: auto;
}
.select-hide {
    display: none;
}
.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
} 
#scroll-menu .select-selected::after {
    color: #000 !important;
    position: absolute;
    border: solid rgba(0, 0, 0, .30196078431372547);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
    content: "";
    transform: rotate(45deg);
    left: 83%;
    top: 12px !important;
    transition: all 500ms ease-in;
}
#scroll-menu .select-selected.select-arrow-active::after {
    transform: rotate(-135deg);
    margin-top: 5px;
}
#scroll-menu .select-items {
    width: 87%;
}
@media (min-width:992px) {
    .custom-select-wrapper {
        max-width: 260px;
        margin-top: 20px;
    }
    .select-items {
        width: 88%;
    }
    .select-selected::after {
        left: 85%;
    }
}