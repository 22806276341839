/* ==========================================================================
   GRID ITEM -- CARD
   ========================================================================== */
   body {
	.grid-item--card {
        content-visibility: auto; 
        contain-intrinsic-size: 1px 5000px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: minmax(.8fr, 1fr) .2fr;
        gap: 0px 0px;
        grid-template-areas: "grid-item--card-body" "grid-item--card-footer";

        [class^="grid-item--card-"] {
                display: flex;
                flex-direction: column;
        }

        .grid-item--card-body {
            grid-area: grid-item--card-body;
        }

        .grid-item--card-footer {
            grid-area: grid-item--card-footer;
            flex-direction: row;
            align-items: flex-end;
        }
	}
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
    // for Internet Explorer 10/11
@media all and (-ms-high-contrast:none) {
    .grid-item--card {
        display: -ms-grid;
        -ms-grid-columns: 1fr;
        -ms-grid-rows: 0.5fr 0.8fr .1fr;
    }

    .grid-item--card-header {
        -ms-grid-row: 1;
        -ms-grid-row-span: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;
    }

    .grid-item--card-body {
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;
    }

    .grid-item--card-footer {
        -ms-grid-row: 3;
        -ms-grid-row-span: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;
    }

}
