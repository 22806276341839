/* ==========================================================================
   FEATURED ARTIST STYLES
   ========================================================================== */
body {
    .featured-artist {

        .title-page {
            margin-bottom: 1rem;
            @include font-size(40, 4vw, 44);
            font-weight: 100;
        }

        ul {
            padding: 0;
            margin: 0 0 2rem;

            &.social-share-list.horizontal {
                justify-content: flex-start !important;
            }
        }
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media (max-width: 575.98px) {

    .featured-artists ul li a {
        margin-bottom: 10px;
    }

}