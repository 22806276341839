.paintload-hero {
    background-size: cover;
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 50vh !important;

    .hero-top-image {
        position: absolute;
        // top: 100px;
        left: 0;
    }
}

@media (max-width:990px) {
    .hero-arrow {
        bottom: -30px;
        position: absolute;
        left: 15%;
        cursor: pointer;
        z-index: 9;
        display: none;
    }
}

body .paintload-page .flow-button.project-all-project:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
    color: #fff !important;
}

.paintload-page {
    padding-bottom: 0 !important;
}

@media (min-width:991px) {
    .paintload-page {
        padding-bottom: 0 !important;
    }

    .paintload-logo {
        position: absolute;
        left: 0;
        max-width: 200px;
    }

    .paintload-hero {
        background-size: cover;
        background-repeat: no-repeat !important;
        background-position: center !important;
        height: 85vh !important;
        position: relative;

        .hero-top-image {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .hero-arrow {
        position: absolute;
        bottom: -30px;
        left: 20%;
        cursor: pointer;
        z-index: 9;
    }

    .paintload-page #main-scroll-menu {
        max-width: none !important;
        left: 0;
    }

    .scrolling .paintload-page .desk-flow-menu {
        top: 43px !important;
    }

    .scrolling .paintload-page #main-scroll-menu {
        height: 110px;
    }

    .paintload-page #main-scroll-menu {
        max-width: none !important;
        left: 0;
        background-color: #000 !important;
        height: 145px;
        transition: all ease 300ms;
    }

    .paintload-page .desk-flow-menu {
        justify-content: end !important;
        background-color: #000;
        padding: 15px 15px !important;
        max-width: 1140px;
        margin-right: auto;
        margin-left: auto;
        top: 79px !important;
    }

    .paintload-page .desk-flow-menu .flow-button.project-all-project {
        background-color: #fff !important;
        border-radius: unset !important;
        color: #000 !important;
        transition: all ease 500ms;
    }

    .paintload-page .desk-flow-menu .flow-button.project-all-project:hover {
        background-color: #FF0089 !important;
        transform: rotate(3deg);
        color: #fff !important;
    }
}

.paintload-page .scroll-menu {
    background-color: #000;
}

@media (max-width:767px) {
    .paintload-hero {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat !important;
        background-position: unset !important;
        height: 50vh !important;
    }

    .paintload-page .scroll-menu-wrapper img {
        max-width: 120px !important;
    }

    .paintload-page .menu-select-project .menu-projects {
        max-width: 135px !important;
        appearance: none;
        font-size: 14px;
    }

    body .paintload-page header#primary-nav-warpper {
        background-color: #000 !important;
    }

    .paintload-page .scroll-menu {
        background-color: #000;
        min-height: 80px;
    }

    .paintload-page .menu-select-project {
        margin-left: auto;
        margin-right: auto;
        max-width: 55%;
        position: relative;
    }

    .paintload-page .menu-select-project::after {
        // content: "\25BC";
        content: "";
        display: block;
        position: absolute;
        right: 38px !important;
        top: 13px;
        font-size: 14px;
    }
}

.product-link {
    color: #fff;
    border-radius: 1px !important;
    font-family: Hind;
    font-size: 15px;
    line-height: 30px;
    text-transform: uppercase !important;
}

.product-content {
    font-weight: 500;
    line-height: 25px;
}

.related-items.useful-products h3 {
    font-size: 16px;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0;
    font-weight: 550;
    font-family: Hind;
}

.rigth-border {
    border-left: 1px solid #dee2e6 !important;
}

.inspire-me-header {
    font-family: LuckyFellas, cursive, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3.5rem;
}

.zero-top-border {
    border-top: 0px !important;
}

div.zoomIn {
    background-position: 50% 50%;
    position: relative;
    overflow: hidden;
    cursor: zoom-in !important;
  }

  div.zoomIn img:hover {
    opacity: 0;
  }

  div.zoomIn img {
    transition: opacity 0.5s;
    display: block;
    width: 100%;
  }