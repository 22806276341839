.faq-right-image-desktop {
    display: none;
}

.faq-right-image-mobile {
    display: block;
}

.faq-title {
    font-size: 50px;
    line-height: 55px;
    letter-spacing: -1px;
    background-color: #000;
    max-width: 140px;
    padding: 10px;
    color: #fff;
    margin-top: 54px;
    margin-bottom: 34px;
    max-height: 76px;
}

.faq-subtitle + p {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.48px;
    color: #030209;
}

.image-title-container {
    margin-bottom: 34px;
}

@media (min-width:991px) {
    .faq-subtitle + p {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.6px;
    }

    .faq-right-image-desktop {
        display: block;
    }

    .faq-right-image-mobile {
        display: none;
    }

    .image-title-container {
        margin-bottom: 0px;
    }
}