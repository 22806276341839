.description-image {
  margin-bottom: 56px;

  @media (max-width: 767px) {
    margin-bottom: 4px;
  }

  .social-share {
    margin-left: -12px;

    .social-share-list.horizontal {
      margin-bottom: 29px;

      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: 767px) {
    .d-block.d-md-none.d-lg-none {
      margin-bottom: 20px;
    }
  }
}




.grid-container {
  display: grid;
  grid-gap: 10px;
  max-width: 1140px;
  margin: 0 auto;

  .grid-item {
    text-align: center;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    div {
      height: 100%;
    }

    &.tall {
      grid-row: span 2;
    }

    &.wide {
      grid-column: span 2;

      @media(max-width: 767px) {
        grid-column: span 1;
      }

      img {
        max-height: 360px;
      }

    }
  }
}

.carousel-container {
  margin: 0 auto;
  position: relative;

  .carouse-title {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.8px;
    margin-bottom: 53px;
    padding-top: 100px;

    @media (max-width:767px) {
      padding-top: 27px;
      font-size: 30px;
      line-height: 40px;
      line-height: -0.6px;
      margin-bottom: 22px;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 110px;
  }

  .carousel-image {
    display: block;
    height: auto;

    @media (max-width: 767px) {
      width: 90%;
      margin: auto;
    }

    @media (min-width: 768px) {
      width: 85%;
      margin: auto;
    }
  }

  .thumbnails-container {
    display: flex;
    justify-content: center;
    overflow-x: auto;

    @media (max-width: 767px) {
      display: none;
    }

    @media (min-width: 768px) {
      margin-top: 59px;


      .arrow-left {
        border: 1px solid black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 11px;
        content: "";
        z-index: 999;
        transform: rotate(135deg);
        height: 24px;
        position: absolute;
        left: 0;
        bottom: 136px;
        cursor: pointer;
      }

      .arrow-right {
        border: 1px solid black;
        border-top-width: 1px;
        border-right-width: 1px;
        border-bottom-width: 1px;
        border-left-width: 1px;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 11px;
        content: "";
        z-index: 999;
        transform: rotate(-45deg);
        height: 24px;
        position: absolute;
        right: 0;
        bottom: 136px;
        cursor: pointer;
      }
    }

    .thumbnail {
      width: 135px;
      max-height: 80px;
      min-height: 80px;
      margin-right: 19px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .thumbnail.active {
      border: 2px solid #007135;
    }
  }
}


@media (max-width: 767px) {
  .carousel-container {

    padding-bottom: 44px;

    .arrow-left {
      border: 1px solid black;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 11px;
      content: "";
      z-index: 9;
      transform: rotate(135deg);
      height: 24px;
      position: absolute;
      left: 10px;
      top: 50%;
      cursor: pointer;
    }

    .arrow-right {
      border: 1px solid black;
      border-top-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 1px;
      border-left-width: 1px;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 11px;
      content: "";
      z-index: 9;
      transform: rotate(-45deg);
      height: 24px;
      position: absolute;
      right: 10px;
      top: 50%;
      cursor: pointer;
    }
  }
}





// .arrow-container {
//   display: flex;
//   align-items: center;
//   margin-top: 10px;
// }

// .arrow {
//   cursor: pointer;
//   padding: 10px;
//   font-size: 20px;
// }

// .arrow-left {
//   margin-right: 10px;
// }

// .arrow-right {
//   margin-left: 10px;
// }

@media (max-width: 767px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 35px;
  }

  .embed-container.video {
    padding-top: 0;
  }

  .artist-projects {
    .product-link {
      margin-bottom: 35px;
      width: 100%;
    }
  }
}

@media (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 75px;
  }
}


.artist-projects {
  background-color: #F6F6F6;
  padding: 97px 0 109px;

  .projects-title {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.8px;
    margin-bottom: 16px;
  }

  .right-side {
    .carousel--full-width.header-carousel {
      .carousel-inner {
        .carousel-item {
          .carousel-caption {
            top: 43%;
            width: auto;
          }
        }
      }
    }
  }

  .text-light.dymo.dymo-added:nth-child(2) {
    margin-bottom: 4px;
  }
}