/* ==========================================================================
   GRID SECTIONS GRID
   ========================================================================== */
   body {
    .discover-title {
        font-family: LuckyFellas;
        font-size: 56px;
        font-weight: 400;
        line-height: normal;
    }

    .discover-title ~ p {
        max-width: 597px;
    }

    .grid-item {

        &-inspiration,
        &-video,
        &-artist,
        &-technique {
            content-visibility: auto;
            contain-intrinsic-size: 1px 5000px;
            min-height: 450px;
            background-size: cover;

            #{$hover} {
                text-decoration: none;

                .material-icons {
                    color: var(--color-link-hover);
                }

                .btn {
                    background-color: var(--color-link-hover);
                    border-color: var(--color-accent-8--darken-p10);
                    color: var(--color-light);
                }
            }
        }

        &-inspiration {
            padding-top: 3rem;

            h2 {
                @include font-size(14, 4vw, 24);
                @include regular(normal);
                margin-bottom: 5px;
            }

            h3 {
                @include font-size(20, 4vw, 24);
                @include bold(normal);
                margin-bottom: 5px;
            }

            p {
                @include font-size(12, 4vw, 16);

                .btn {
                    @include font-size(10, 4vw, 16);
                    padding: 15px;
                }
            }
        }

        &-video {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 5fr 1fr 1fr;
            grid-template-areas: "video-top" "video-middle" "video-bottom";

            &>span {
                align-self: end;
            }

            .video-top {
                grid-area: video-top;
                align-self: end;
                margin-bottom: 30px;
            }

            .video-middle {
                grid-area: video-middle;
                align-self: end;

                p {
                    line-height: 38px;
                }
            }

            .video-bottom {
                grid-area: video-bottom;
            }


            .video-middle,
            .video-bottom {
                opacity: 0;
                @extend .animate;
            }

            #{$hover} {

                .video-middle,
                .video-bottom {
                    opacity: 1;
                    @extend .animate;
                }
            }

            .text-dark {
                display: flex;
                justify-content: center;

                i {
                    justify-self: center;
                    align-self: center;
                    @include font-size(50, 4vw, 56);
                    color: var(--color-dark);
                }

                #{$hover} {
                    text-decoration: none !important;
                }
            }

            .btn {
                margin-bottom: 1rem;

                #{$hover} {
                    background: var(--color-accent-8);
                }
            }
        }


        &-artist,
        &-technique {
            padding: 0;
            padding-top: 3rem;

            h2 {
                @include font-size(12, 4vw, 16);
                @include regular(normal);
                margin-bottom: 5px;

                span {
                    background-color: var(--color-dark);
                }
            }

            p {
                @include font-size(10, 4vw, 16);
                @include light(normal);

                margin-bottom: 5px;

                &:last-of-type {
                    margin-bottom: 1rem;
                }

                span.btn {
                    @include font-size(8, 4vw, 12);
                    padding: 10px;
                }
            }

            span:not(.btn) {
                width: 100%;
                opacity: 0;
                @extend .animate;
                //@include linearGradient(rgba(var(--color-dark, .0), var(--color-dark, bottom);
            }

            span.discover-view:not(.btn) {
                opacity: 1;
            }

            span.discover-view {
                .dymo-text {
                    opacity: 1;
                }
            }

            #{$hover} {
                span {
                    opacity: 1;
                    @extend .animate;
                }
            }
        }

        &-technique {}

    }



    .grid-related .col-md-4 .bg-dynamo {
        padding-top: 3px;
        padding-bottom: 3px;
        line-height: 30px;
    }


    .product {
        .grid-item {

            &-inspiration,
            &-video,
            &-artist,
            &-related {
                min-height: 379px;
            }
        }
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

@media (max-width: 991.98px) {
    body {
        .grid-item-artist.col-md-4 {
            span {
                opacity: 1;
            }
        }

        .grid-item-video {

            .video-middle,
            .video-bottom {
                opacity: 1;
            }

        }
    }
}

@media (max-width: 768px) and (max-width: 1024px) {
    body {
        .grid-item {

            &-inspiration,
            &-video,
            &-artist,
            &-related {

                h3 {
                    @include font-size(16, 4vw, 19);
                    @include bold(normal);
                    margin-bottom: 5px;
                }

                p {
                    @include font-size(10, 4vw, 16);
                    @include light(normal);
                }

            }
        }
    }
}