/* ==========================================================================
   NAVIGATION
   ========================================================================== */

.scroll-menu {
    background-color: #0f4288;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    transition: top 0.3s;
    z-index: 99;
}

.menu-select-project .menu-projects {
    border: #f5f5f5 !important;
    padding: 10px;
    min-width: unset;
    border-radius: 6px;
    width: 100%;
    background-color: #f5f5f5 !important;
    appearance: none;
}
// .menu-select-project::after {
// 	position: absolute;
// 	top: 14px;
// 	border: solid rgba(0,0,0,.30196078431372547);
// 	border-width: 0 3px 3px 0;
// 	display: inline-block;
// 	padding: 5px;
// 	content: "";
// 	transform: rotate(45deg);
// 	left: 82%;
// }
// .menu-select-project.floating-menu-rotate::after {
// 	transform: rotate(-135deg);
// 	margin-top: 5px;
// }   
.arrow {
    border: solid #fff;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    &.up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }
}

.scroll-menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    img {
        max-width: 58px;
    }
    button {
        background-color: transparent;
        border: 0;
    }
}

.main-scroll-menu {
    display: none;
}

.desk-flow-menu {
    display: none;
}

@media (min-width: 992px) {
    header#primary-nav-warpper {
        z-index: 99 !important;
    }
    .main-scroll-menu {
        display: block;
        background-color: transparent;
        position: fixed;
        background-color: #fff;
        transition: top 0.3s;
        z-index: 98;
        top: 0 !important;
    }
    #nav-main {
        background-color: #fff;
    }
    .scroll-menu {
        display: none;
    }
    .desk-flow-menu {
        display: flex;
        align-items: center;
        position: absolute;
        top: -100%;
        right: 0;
        left: 0;
        padding: 10px 15px !important;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        background-color: rgba(255, 255, 255, 0.9);
        transition: top 0.3s;
        z-index: -1;
        .flow-hashtag {
            margin-right: 25px;
            color: #1e1e1f;
            font-size: 20px;
            &:hover {
                color: #1e1e1f !important;
                text-decoration: none !important;
            }
        }
        .flow-button {
            color: #fff;
            border-radius: 6px;
            border: 0;
            margin-right: 10px;
            padding: 5px 10px;
            &.project-all-project {
                background-color: #696969 !important;
            }
            &.project-acrylic {
                background-color: #f08a1c !important;
            }
            &.project-watercolour {
                background-color: #02adef !important;
            }
            &.project-gouache {
                background-color: #0a4b91 !important;
            }
            &.project-oil {
                background-color: #d92424 !important;
            }
            &.project-drawing {
                background-color: #5b3d82 !important;
            }
        }
    }
}