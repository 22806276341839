.artist-title {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -.8px;
    color: #fff !important;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}

.artist-title::before {
    content: "";
    display: block;
    position: absolute;
    background-color: #00bea8;
    width: 165px;
    height: 58px;
    z-index: -1;
    top: -5px;
    left: -10px;
    transform: rotate(2deg);
}

.paintload-artist-container {
    .artist-img {
        margin-top: 25px;
    }

    .paintload-artist-desc>p {
        margin-top: 25px;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0px;
        color: #000D0D;
        margin-bottom: 32px;
    }

    .paintload-artist-btn-desktop {
        display: none;
    }
}

.paintload-artist-image-title {
    font-size: 20px;
    margin-top: 10px;
    line-height: 30px;
    letter-spacing: -0.4px;
    color: #000D0D;
    text-align: center;
    padding: 3px;
}

.paintload-artist-images-container {
    .paintload-artist-btn-mobile {
        background-color: #000d0d;
        padding: 8px 27px;
        margin: 40px auto 15px;
        display: flex;
        max-width: 175px;
        text-align: center;
        color: #fff !important;
    }
}

.paintload-artist {
    background-color: #fff;
}
.paintload-page .paintload-artist-btn-desktop:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
	color: #fff !important;
}
@media (max-width:767px) {
    .paintload-artist-hero-img {
        margin-top: -25px;
    }

    .paintload-artist-img {
        width: 100%;
        height: auto;
    }

    .paintload-artist-top-image {
        margin-top: -30px;
        margin-bottom: 40px;
    }
}

@media (max-width:990px) {
    .paintload-artists-arrow {
        position: relative;
        left: 4%;
        top: 30px;
        z-index: 19;
    }

    .image-description {
        display: none;
    }

    .artist-title {
        left: 10px !important;
    }
    .artist-title::before {
        transform: rotate(-1deg);
    }
}

@media (min-width:991px) {
    .paintload-artist {
        position: relative;
        padding-top: 114px;

        .paintload-artist-hero-img {
            position: absolute;
            right: 0;
            top: -44px;
            cursor: pointer;
        }
    }

    .paintload-artist-btn-desktop {
        display: block !important;
        margin-top: 40px;
        background-color: #000d0d;
        padding: 0px 22px;
        margin-bottom: 36px;
        max-width: 175px;
        color: #fff !important;
        font-size: 18px;
        line-height: 40px;
        letter-spacing: -0.36px;
        text-align: center;
        transition: all ease 500ms;
    }


    .paintload-artist-images-container {
        margin-top: 25px;
        padding-bottom: 0px;

        .paintload-artist-btn-mobile {
            display: none;
        }
    }

    .paintload-artists-arrow {
        position: relative;
        left: 15%;
        top: 30px;
        z-index: 18;
        cursor: pointer;
    }

    .image-wrap {
        position: relative;
    }

    .image-description {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(10, 11, 11, 0.72);
        color: #fff;
        visibility: hidden;
        opacity: 0;
        transition: opacity .2s, visibility .2s;
        height: 100%;
        font-size: 16px;
        line-height: 26px;
        padding: 32px;
    }

    .image-wrap:hover .image-description {
        visibility: visible;
        opacity: 1;
    }

    .image-wrap:hover+.paintload-artist-image-title {
        background-color: #FF0089;
        max-width: 130px;
        margin-left: auto;
        margin-right: auto;
        transform: rotate(-2deg);
        color: #fff !important;
        transition: all ease 500ms;
    }
}

body a:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
    color: unset !important;
    text-decoration: unset !important;
}

.paintload-page .paintload-artist-btn-desktop:hover {
    background-color: #00BEA8 !important;
    color: #fff !important;
    transform: rotate(2deg);
}