/* ==========================================================================
   404 STYLES
   ========================================================================== */

body .main-content .error-404 {
    min-height: 60vh;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */