/* ==========================================================================
   MAINTENANCE STYLES
   ========================================================================== */

body .maintenance-page {
    padding-top: 0 !important;
    margin: -4.7rem 0 0 !important;
    height: 100vh;
    background: transparent url('../images/bg-water-colour@2x.png') no-repeat top right;
    background-size: 60vw;

    .maintenance {
        height: 100vh;
        padding: 15vh 0 !important;
        background: transparent url('../images/bg-brush@2x.png') no-repeat 75% 120%;
        background-size: 500px;

        .message {
            margin-top: 10rem;
            margin-bottom: 4rem;
            h1 {
                @include font-size(40, 4vw, 70);
                font-weight: 600;
            }
            h2{}
            p{
                @include font-size(18, 4vw, 20);
            }

        }

        aside.social-share ul.social-share-list.horizontal li a.btn.btn-white {
            background: var(--color-accent-7);
        }
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

// MOBILES
@include media-breakpoint-down(sm) {
    body {

        .maintenance-page {
            background-size: 100vw;
            .maintenance {
                padding: 10vh 0 !important;
                background-position-y: 105%;
                background-size: 200px;
                .message {
                    margin-top: 5rem;
                }
            }
        }
    }
}

// MOBILES
@include media-breakpoint-down(md) {
    body {

        .maintenance-page {
            background-size: 80vw;
            .maintenance {
                background-position-x: 110%;

            }
        }
    }
}