/* ==========================================================================
   range STYLES
   ========================================================================== */

.range {

    .tabs-range {

        .nav-tabs {
            border: none !important;
        }

        .nav-item {
            a {
                color: $dark;

                &.active {
                    border-color: transparent transparent var(--color-accent-5);
                    background-color: var(--color-accent-5);
                }
            }
        }

        .container-fluid {
            background-color: var(--color-accent-5);
        }

        #rangeTabContent {
            content-visibility: auto;
            contain-intrinsic-size: 1px 5000px;

            .key {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    display: flex;

                    &.title {
                        margin-bottom: 8px;
                    }

                    i {
                        margin-right: 5px;
                    }
                }
            }

            #downloads {

                .col-md-4 {
                    content-visibility: auto;
                    contain-intrinsic-size: 1px 5000px;
                }

                .btn {
                    display: inline-flex;
                    align-items: center;

                    ion-icon {
                        margin-right: 10px;
                    }
                }
            }


            .row .col {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
            }

            .expander {
                .show-hide-area.active {
                    height: 30vh;
                    overflow: hidden;

                    &:after {
                        display: block;
                        position: absolute;
                        bottom: 4vh;
                        content: '';
                        width: 100%;
                        height: 6rem;
                        background: var(--color-accent-5);
                        background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, var(--color-accent-5) 100%);
                    }
                }

                .btn-show-hide {
                    text-transform: uppercase;
                }
            }

            .swatch-grid-container {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                grid-template-areas: "swatch swatch swatch-description swatch-description swatch-description""swatch swatch swatch-description swatch-description swatch-description";
                margin-bottom: 15px;
                flex: 0 1 23%;
                margin-right: 15px;

                .swatch {
                    grid-area: swatch;
                    min-height: 110px;
                }

                .swatch-description {
                    grid-area: swatch-description;
                    display: flex;
                    padding-left: 1rem;

                    p {
                        margin: 0;
                        align-self: center;
                        line-height: 1.2;

                        span {
                            @include font-size(9, 4vw, 14);
                            color: var(--color-accent-6--lighten-p40);
                        }
                    }
                }
            }

            // IE 10/11
            @media all and (-ms-high-contrast:none) {
                .swatch-grid-container {
                    display: -ms-grid;
                    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
                    -ms-grid-rows: 1fr 1fr;

                    .swatch {
                        -ms-grid-row: 1;
                        -ms-grid-row-span: 2;
                        -ms-grid-column: 1;
                        -ms-grid-column-span: 2;
                    }

                    .swatch-description {
                        -ms-grid-row: 1;
                        -ms-grid-row-span: 2;
                        -ms-grid-column: 3;
                        -ms-grid-column-span: 3;
                    }
                }
            }
        }
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
// MOBILES
@include media-breakpoint-down(sm) {

    .range {
        .tabs-range {
            #rangeTabContent {
                .expander {
                    .show-hide-area.active {
                        height: 45vh;

                        &:after {
                            bottom: 7vh;
                            height: 6rem;
                        }
                    }
                }
            }
        }
    }
}

// TABLETS
@include media-breakpoint-down(md) {

    ul#myTab {
        display: unset;
        overflow-x: scroll;

        &::after {
            content: '>';
            display: block;
            width: 15px;
            height: 15px;
            position: absolute;
            right: 0;
        }
    }


    .range {
        .tabs-range {
            #rangeTabContent {
                .swatch-grid-container {
                    grid-template-columns: 0fr 1fr 1fr 1fr 1fr;
                }

                .expander {
                    .show-hide-area.active {
                        height: 37vh;

                        &:after {
                            bottom: 5vh;
                            height: 6rem;
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 991.98px) {
    .range .tabs-range #rangeTabContent {

        // column-count: 2;
        .swatch-grid-container {
            grid-template-columns: 0fr 0.5fr 1fr 1fr 1fr;
            flex: 0 1 44%;
        }
    }
}

