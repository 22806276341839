/* ==========================================================================
   FULLWIDTH HEADER
   ========================================================================== */
.fullwidth-header {
    content-visibility: auto; 
    contain-intrinsic-size: 1px 5000px;
    margin-top: -3rem;
    margin-bottom: 3rem;
}
.sustainability .fullwidth-header {
    margin-top: unset;
}
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media (min-width: 1366px) {
    .fullwidth-header {
        max-width: 1140px;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;

        &.video {
            max-width: unset;
            padding: 0;
        }
    }
}