/* ==========================================================================
   KEY STYLES
   ========================================================================== */
   .key {
    list-style: none;
    margin: 0;
    padding: 0;

    .title {
        margin-bottom: 8px;
    }

    li {
        display: flex;
        align-items: center;
        @include font-size(9, 4vw, 14);
        line-height: 2;

        & i {

            &.material-icons:last-of-type,
            &.transparency {
                margin-right: .5rem;
            }
        }
    }


    .key-size {

        &--item {
            display: grid;
            grid-template-columns: 70px;
            grid-template-rows: 45px 25px;
            gap: 2px 0px;
            grid-template-areas:
                "key-size--item-icon"
                "key-size--item-description";

            &-icon {
                grid-area: key-size--item-icon;
            }

            &-description {
                grid-area: key-size--item-description;
                @include font-size(9, 4vw, 14);
            }
        }
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

@media (max-width: 991.98px) {
    .key {
        li {
            // @include font-size(10, 4vw, 16);
        }
    }
}

@media (max-width: 575.98px) {}