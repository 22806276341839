/* ==========================================================================
   MEGA INTRO
   ========================================================================== */
.jumbo-intro {
    min-height: 600px;
    display: flex;
    align-items: center;

    h1 {
        @include font-size(22, 4vw, 26);
        line-height: 36px;
    }

    p {
        @include font-size(14, 4vw, 24);
        line-height: 26px;
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */