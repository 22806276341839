/* ==========================================================================
   RELATED ITEMS STYLES
   ========================================================================== */

.related-items {
    content-visibility: auto;
    contain-intrinsic-size: 1px 5000px;
    // border-top: 13px solid #F7F7F7;
    &.useful-products {
        h3 {
            @include font-size(12, 4vw, 16);
            font-weight: 800;
        }
    }
    .justify-content-end a {
        color: var(--color-accent-8);
    }
    margin-bottom: -5rem;
}


/* ==========================================================================
   Media Queries
   ========================================================================== */