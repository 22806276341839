.section-background {
    height: 160px;
    background: #F08A1C 0% 0% no-repeat padding-box;
    height: 160px;
    position: relative;
    margin-top: 13.7px;
    .dots-image {
        background-image: url("./images/Group\ 1296.png");
        background-position: 42% 64%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 70px;
        position: absolute;
        top: 100%;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.aclylic {
    margin-top: 55px;
}

.acrylic-container {
    .acrylic-paints {
        font-size: 24px;
        font-weight: 500;
        // margin-top: 50px;
        color: #040404;
        line-height: 30px;
        letter-spacing: -0.48px;
    }
    .acrylic-desc p {
        line-height: 26px;
    }
    .show-products {
        padding: 10px;
        background-color: #f08a1c;
        color: #fff;
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 35px;
        display: inline-block;
        border-radius: 5px;
        width: 100%;
        text-align: center;
        line-height: 26px;
        letter-spacing: -.48px;
    }
    .projects-title {
        border-top: 1px solid #a2a3a2;
        padding-top: 24px;
        font-size: 25px;
        line-height: 30px;
    }
    .projects-images {
        display: flex;
        flex-wrap: wrap;
    }
}

.dots-img-container {
    .Acrylic-background {
        display: inline-block;
        position: relative;
        margin-top: -100px;
        z-index: 2;
        margin-bottom: 0;
        top: -40px;
        left: -45px;
    }
    .Acrylic {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        font-size: 40px;
        line-height: 60px;
        letter-spacing: -0.8px;
        color: #F08A1C;
    }
}

.projects-images {
    .product-img {
        margin-right: 4px;
        min-width: 168px;
        max-height: 115px;
        padding-bottom: 10px;
    }
}

#simply-range .project-all-project {
    background-color: #696969 !important;
}

#simply-range .project-all-project:hover {
    color: #fff !important;
    background-color: #007a39 !important;
}

.project-acrylic {
    background-color: #f08a1c !important;
}

#simply-range .project-acrylic:hover {
    color: #fff !important;
    background-color: #007a39 !important;
}

.project-watercolour {
    background-color: #02adef !important;
}

#simply-range .project-watercolour:hover {
    color: #fff !important;
    background-color: #007a39 !important;
}

.project-gouache {
    background-color: #0a4b91 !important;
}

#simply-range .project-gouache:hover {
    color: #fff !important;
    background-color: #007a39 !important;
}

.project-oil {
    background-color: #d92424 !important;
}

#simply-range .project-oil:hover {
    color: #fff !important;
    background-color: #007a39 !important;
}

.project-drawing {
    background-color: #5b3d82 !important;
}

#simply-range .project-drawing:hover {
    color: #fff !important;
    background-color: #007a39 !important;
}
.project-creative {
    background-color: #C84288 !important;

}
#simply-range .project-creative:hover {
    color: #fff !important;
    background-color: #007a39 !important;
}
.project-activity {
    background-color: #80B875 !important;

}
#simply-range .project-activity:hover {
    color: #fff !important;
    background-color: #007a39 !important;
}
.project-acrylic-text {
    color: #f08a1c !important;
}

.project-watercolour-text {
    color: #02adef !important;
}

.project-gouache-text {
    color: #0a4b91 !important;
}

.project-oil-text {
    color: #d92424 !important;
}

.project-drawing-text {
    color: #5b3d82 !important;
}
.project-creative-text {
    color: #C84288 !important;
}
.project-activity-text {
    color: #80B875 !important;
}

.full-range-title {
    font-size: 36px;
    line-height: 50px;
    letter-spacing: -0.72px;
}

.project-btn {
    padding: 4px 7px;
    border-radius: 4px;
    color: #fff !important;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.32px;
    margin-bottom: 10px !important;
    display: inline-block;
}

#simply-range .project-btn:hover {
    color: #fff !important;
    background-color: #007a39 !important;
}

#campaign-slider .carousel-inner .carousel-item {
    height: auto !important;
    max-height: none !important;
}

// #campaign-slider .carousel-indicators {
//     display: none;
// }
#campaign-slider .carousel-control-prev-icon {
    background-image: url(/global/images/campaign-images/chevron-left.png) !important;
}

#campaign-slider .carousel-control-next-icon {
    background-image: url(/global/images/campaign-images/chevron-right.png) !important;
}
.projects-img-desc p {
    display: none;
}



@media (max-width:767px) {
    .images-container {
        display: none;
    }
    .arrow-image {
        display: none;
    }
    .black-dots {
        display: none;
    }
    .left-img {
        display: none;
    }
    .hero-section {
        overflow: hidden;
    }
    .down-arrow-container {
        display: none;
    }
//     #campaign-slider .carousel-control-next-icon,
// #campaign-slider .carousel-control-prev-icon {
//     width: 40px !important;
//     height: 40px !important;
//     position: relative;
//     top: -530px;
// }
    #campaign-slider .carousel-control-next, 
    #campaign-slider .carousel-control-prev {
        opacity: 1 !important;
        top: 20px;
        width: 40px !important;
        height: 40px !important;
    }
    #campaign-slider .carousel-control-prev {
        left: 0px !important;
    }
    #campaign-slider .carousel-control-next {
        right: 0px !important;
    }
    #campaign-slider .carousel-control-next-icon, 
    #campaign-slider .carousel-control-prev-icon {
        width: 40px !important;
        height: 40px !important;
    }
    #campaign-slider .carousel-indicators {
    display: none;
}
}

@media (min-width:992px) {
    .projects-img-desc {
        position: relative;
        max-width: 169px;
        max-height: 115px;
    }
    // .projects-img-desc:hover::after {
    //     content: "";
    //     width: 97%;
    //     height: 100%;
    //     display: block;
    //     background: rgba(0,0,0,0.4);
    //     top: 0;
    //     position: absolute;
    //     transition: all 500ms ease-in;
    // }
    .projects-img-desc p {
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        opacity: 0;
        background: rgba(0,0,0,0.4);
        width: 100%;
        height: 100%;
        text-align: center;
        padding-top: 50px;
        margin-bottom: 0;
        max-width: 163px;
        max-height: 115px;
        transition: all 300ms ease-in;
        display: block;
    }
    .projects-img-desc p:hover {
        opacity: 1;
        text-indent: -9999px;
        overflow: hidden;
    }
    .aclylic {
        margin-top: 150px !important;
    }
    #aclyric {
        margin-bottom: 0px;
    }
    #watercolour {
        margin-bottom: 0px;
    }
    #gouche {
        margin-bottom: -15px;
    }
    .acrylic-container {
        .acrylic-paints {
            font-size: 35px;
            margin-top: 0px;
            color: #040404;
            max-width: 530px;
            line-height: 40px;
            letter-spacing: -0.7px;
        }
        .acrylic-desc p {
            font-size: 16px;
            line-height: 26px;
            max-width: 477px;
        }
        // .show-products {
        //     font-size: 24px;
        //     margin-bottom: 35px;
        //     padding-top: 15px;
        //     padding-bottom: 15px;
        // }
        .show-products {
            padding: 20px;
            color: #fff;
            min-width: 342px;
            line-height: 26px;
            font-size: 24px;
        }
        margin-bottom: 50px;
        .projects-title {
            font-size: 22px;
            border-top: 2px solid #a2a3a2;
            padding-top: 25px;
            line-height: 30px;
        }
        .projects-images {
            .product-img {
                min-width: 163px;
                max-height: 115px;
                padding-bottom: 0;
                margin-right: 6px;
            }
            display: flex;
            flex-wrap: nowrap;
        }
    }
    .section-background {
        .dots-image {
            background-image: url("./images/Group\ 1296.png");
            background-position: 0% 64%;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100px;
            position: absolute;
            top: 100%;
            bottom: 0;
            left: 0;
            right: 0;
        }
        height: 160px;
        position: relative;
        margin-top: 40px;
    }
    .dots-img-container {
        .Acrylic-background {
            display: inline-block;
            position: relative;
            margin-top: -20px;
            z-index: 2;
            margin-bottom: 0;
            top: -73px;
            left: -30px;
        }
        .Acrylic {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            font-size: 40px;
            line-height: 60px;
            letter-spacing: -0.8px;
            // color: #F08A1C;
        }
    }
    .images-container {
        .second-image {
            position: absolute;
            top: 120px;
            left: 241px;
        }
        position: relative;
        margin-top: 100px;
        margin-left: 100px;
        .third-image {
            margin-top: -40px;
        }
    }
    .full-range-title {
        font-weight: 600;
    }
    .project-btn {
        padding: 7px 23px;
        border-radius: 4px;
        color: #fff !important;
        margin-right: 5px;
    }
    .project-btn:hover {
        text-decoration: none;
    }
    .tabs {
        display: flex;
        align-items: center;
        justify-content: end;
    }
    // .navigation-tabs {
    //     margin-top: 100px;
    // }
    .first-image,
    .second-image {
        visibility: hidden;
    }
    #campaign-slider .carousel-indicators {
        margin-bottom: -25px !important;
    }
    #campaign-slider .carousel-indicators li {
        -webkit-box-sizing: content-box !important;
        box-sizing: content-box !important;
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 1 auto !important;
        flex: 0 1 auto !important;
        width: 14px !important;
        height: 14px;
        border-radius: 50% !important;
        margin-right: 3px !important;
        margin-left: 3px !important;
        text-indent: -999px !important;
        cursor: pointer !important;
        background-color: #D0D0D0 !important;
        background-clip: padding-box !important;
        opacity: 1 !important;
        -webkit-transition: opacity .6s ease !important;
        transition: opacity .6s ease !important;
    }
    #campaign-slider .carousel-indicators .active {
        opacity: 1;
        background-color: #031C40 !important;
    }
    #campaign-slider .carousel-control-prev {
        left: 80px;
    }
    #campaign-slider .carousel-control-next {
        right: 80px;
    }
    #campaign-slider .carousel-control-next,
    #campaign-slider .carousel-control-prev {
        opacity: 1 !important;
        top: 400px !important;
        width: 40px;
        height: 40px;
    
    }
    #campaign-slider .carousel-control-next-icon, 
    #campaign-slider .carousel-control-prev-icon {
        width: 40px !important;
        height: 40px !important;
    }
}