/* ==========================================================================
   Recommended products STYLES
   ========================================================================== */
.recommended-products {
    content-visibility: auto; 
    contain-intrinsic-size: 1px 5000px;
    .col-md-4 {
        display: flex;
        height: 350px;
        margin-bottom: 2rem;
    }

    .recommended-product {
        flex: 1 1 0;
        display: flex;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        justify-content: center;
        // padding: 2rem 1rem;
        color: var(--color-dark);

        a {
            color: var(--color-dark);
        }

        h3 {
            font-weight: 100;
            background: var(--color-accent-7);
            align-self: flex-start;
            justify-content: center;
            align-items: center;
            padding: 5px;
        }
    }
}