/* ==========================================================================
   COLOURS
   ========================================================================== */
:root {
    --color-accent-1 : hsla(0, 0%, 0%, 1);
    --color-accent-1--lighten-p50 : hsla(0, 0%, 50%, 1);
    --color-accent-1--lighten-p75 : hsla(0, 0%, 75%, 1);
    --color-accent-1--lighten-p80 : hsla(0, 0%, 80%, 1);
    --color-accent-1--lighten-p95 : hsla(0, 0%, 95%, 1);

    --color-accent-2 : hsla(203, 93%, 23%, 1);

    --color-accent-3 : hsla(199, 37%, 82%, 1);

    --color-accent-4 : hsla(199, 37%, 92%, 1);

    --color-accent-5 : var(--color-accent-1--lighten-p95);

    --color-accent-6 : hsla(0, 0%, 25%, 1);
    --color-accent-6--lighten-p30 : hsla(0, 0%, 55%, 1);
    --color-accent-6--lighten-p40 : hsla(0, 0%, 65%, 1);
    --color-accent-6--lighten-p60 : hsla(0, 0%, 85%, 1);

    --color-accent-7 : hsla(0, 0%, 100%, 1);
    --color-accent-7--darken-p10 : hsla(0, 0%, 90%, 1);

    --color-accent-8 : hsla(148, 100%, 24%, 1);
    --color-accent-8--darken-p10 : hsla(148, 100%, 14%, 1);

    --color-accent-9 : hsla(53, 89%, 59%, 1);

    --color-shadow : hsla(0, 0%, 76%, 1);

    --color-light : var(--color-accent-7);

    --color-dark : var(--color-accent-1);

    --color-error : hsla(350, 83%, 53%, 1);

    --color-quote-accent : hsla(26, 69%, 64%, 1);

    --color-link-hover : var(--color-accent-8);

    --color-renegade-yellow : hsla(48, 100%, 50%, 1); // Our brand colour


    --color-sm-facebook : hsla(221, 44%, 41%, 1); // Facebook Blue
    --color-sm-twitter : hsla(196, 100%, 46%, 1); // Twitter Blue
    --color-sm-googleplus : hsla(7, 71%, 55%, 1); // Google+ Red
    --color-sm-youtube : hsla(0, 100%, 37%, 1); // YouTube Red
    --color-sm-linkedin : hsla(199, 100%, 36%, 1); // LinkedIn+ Blue
    --color-sm-instagram : hsla(207, 34%, 48%, 1); // Instagram Blue
    --color-sm-pinterest : hsla(358, 73%, 46%, 1); // Pinterest Red
    --color-sm-vine : hsla(165, 100%, 37%, 1); // Vine Green
    --color-sm-snapchat : hsla(59, 100%, 50%, 1); // Snapchat Yellow
    --color-sm-flickr : hsla(329, 100%, 50%, 1); // Flickr Pink
    --color-sm-tumblr : hsla(209, 37%, 31%, 1); // Tumblr Dark Turquoise
    --color-sm-vimeo : hsla(79, 61%, 57%, 1); // Vimeo Green
    --color-sm-foursquare : hsla(201, 100%, 35%, 1); // Foursquare Logo Blue
}


// hex to rgba
@mixin rgbaColorIn($color, $opacity : 1) {
    $result: rgba(red($color), green($color), blue($color), $opacity) !global;
}

// Fix for unicode http://goo.gl/sizA99
@function unicode($str) {
    @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"");
}

@mixin buttonColours($buttonBackgroundColor) {

    a {
        span {
            background: $buttonBackgroundColor;
        }

        #{$hover} {
            span {
                background: lighten($buttonBackgroundColor, 10%);
            }
        }
    }
}



.bg {

    &-color-accent-1 {
        background-color: var(--color-accent-1);
    }

    &-color-accent-2 {
        background-color: var(--color-accent-2);
    }

    &-color-accent-3 {
        background-color: var(--color-accent-3);
    }

    &-color-accent-4 {
        background-color: var(--color-accent-4);
    }

    &-color-accent-5 {
        background-color: var(--color-accent-5);
    }

    &-color-accent-6 {
        background-color: var(--color-accent-6);
    }

    &-color-accent-7 {
        background-color: var(--color-accent-7);
    }

    &-color-accent-8 {
        background-color: var(--color-accent-8);
    }

    &-color-accent-9 {
        background-color: var(--color-accent-9);
    }

    &-dark {
        background-color: var(--color-dark) !important;
    }

    &-light {
        background-color: var(--color-light) !important;
    }
}

.text {
    &-dark {
        color: var(--color-dark) !important;
    }
}