/* ==========================================================================
   CAROUSEL -- FULL WIDTH
   ========================================================================== */
body {
    .carousel--full-width {

        &.header-carousel,
        &.quote {
            .carousel-indicators {
                li {
                    height: 8px;
                }
            }

            .carousel-inner {
                .carousel-item {
                    height: 600px;
                    max-height: 90vh;
                }
            }
        }

        &.header-carousel {
            .carousel-inner {
                .carousel-item {
                    background-size: cover;
                    background-repeat: no-repeat;

                    .carousel-caption {
                        top: 15%;
                        bottom: unset !important;
                        right: unset !important;
                        text-align: left;
                        width: 305px;

                        .title {
                            @include font-size(32, 4vw, 36);
                            @include bold(normal);
                            margin-bottom: 15px;

                            &.bg-light.bg-dymo,
                            &.bg-dark.bg-dymo {
                                line-height: 2;
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                        }

                        p {
                            @include font-size(12, 4vw, 16);

                            &.intro-title {
                                @include font-size(14, 4vw, 24);
                                @include regular(normal);
                                margin-bottom: 5px;

                                &.bg-dark.bg-dymo {
                                    line-height: 1;

                                    &::after {
                                        display: none;
                                    }
                                }
                            }

                            &.bg-light.bg-dymo,
                            &.bg-dark.bg-dymo {
                                line-height: 2.2;
                            }
                        }

                        a.btn {
                            @include font-size(10, 4vw, 16);
                            padding: 15px;
                        }
                    }
                }

            }
        }

        &.quote {
            .carousel-item {
                display: flex;
                justify-content: center;

                .carousel-quote {
                    display: flex;
                    flex-direction: column;
                    width: calc(100vw - 30%);

                    .intro-title {
                        @include font-size(26, 4vw, 33);
                        color: var(--color-accent-7);
                        margin: 5rem 0;
                    }

                    blockquote {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-content: stretch;
                        align-items: stretch;

                        @include font-size(40, 4vw, 90);
                        font-weight: 900;
                        color: var(--color-accent-7);

                        // Quote "
                        // Welecome to "The upside down". To get the quote looking as per the design, I need to flip it,
                        // so everything is the wrong way in values
                        span {
                            @import url('https://fonts.googleapis.com/css2?family=Lobster&text=%22&display=swap');
                            font-family: 'Lobster', cursive;
                            transform: scaleX(-1);
                            transform: rotate(180deg);
                            flex: 0 1 80px;
                            font-size: 11rem;
                            line-height: .9;
                            display: flex;
                            align-items: flex-end;
                            margin: -6rem 0 0 0;
                            justify-content: flex-end;
                        }

                        p {
                            margin: 0;
                            flex: 1 1 calc(100% - 80px)
                        }

                        cite {
                            flex: 1 1 100%;
                            margin: 1rem 0 0 80px;
                            @include font-size(16, 4vw, 24);
                        }

                    }
                }
            }
        }
    }


}


/* ==========================================================================
   Media Queries
   ========================================================================== */
@media (max-width: 575.98px) {
    body {
        .carousel--full-width {
            &.quote {
                .carousel-item .carousel-quote {
                    blockquote {
                        span {
                            font-size: 5rem;
                            line-height: .9;
                            flex: 100%;
                        }

                        p {
                            @include font-size(32, 4vw, 40);
                        }

                        cite {
                            @include font-size(16, 4vw, 24);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    body {

        .header-carousel,
        .quote {
            .carousel--full-width {
                .carousel-inner {
                    .carousel-item {
                        height: 600px;
                        max-height: 90vh;
                    }
                }
            }
        }
    }
}