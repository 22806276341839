/* ==========================================================================
   HOME STYLES
   ========================================================================== */
.home-page {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */