@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");

.panel-title>a:before {
    font-family: FontAwesome;
    content: "\2013";
    font-size: 30px;
    color: #383A35 !important;
    font-weight: 400;
    padding-right: 5px;
}

// .panel-title > a.collapsed:before {
//     content:"\f067";
// }
.panel-title>a:hover,
.panel-title>a:active,
.panel-title>a:focus {
    text-decoration: none;
}

.panel-title>a.collapsed::before {
    content: "\002B";
    font-size: 30px;
    color: #383A35 !important;
    font-weight: 400;
}

// .accordion-desc>p {
//     margin-bottom: 32px;
//     margin-left: 10px;
// }
.accordion-desc {
    padding-bottom: 32px;
    padding-left: 10px;
}
.accordion-video {
    font-size: 16px;
    line-height: 30px;
    background-color: #000;
    padding: 6px 10px;
    color: #fff !important;
    margin-bottom: 41px;
    display: inline-block;
}

.panel.panel-default {
    border-bottom: 1px solid #C6C6C6;
}

#accordion .panel.panel-default:first-child {
    border-top: 1px solid #C6C6C6;
}

.accordion-title {
    font-size: 18px;
    line-height: 26px;
    color: #383A35;
    margin-top: 15px;
    display: inline-block;
    margin-bottom: 15px;
}

.faqs-accordion {
    margin-top: 16px;
}
.right-image {
	padding-bottom: 32px;
	text-align: center;
}
@media (min-width:991px) {
    .right-image {
        text-align: right;
    }
    .faqs-accordion {
        margin-top: 32px;
    }
    body .accordion-video:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
        color: #fff !important;
        background-color: #007a39 !important;
    }
    .accordion-title {
        font-size: 24px;
        line-height: 26px;
    }

    .panel.panel-default {
        border-bottom: 2px solid #C6C6C6;
    }

    #accordion .panel.panel-default:first-child {
        border-top: 2px solid #C6C6C6;
    }
}