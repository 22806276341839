/* ==========================================================================
   INSTAGRAM FEED
   ========================================================================== */
body {
    .instagram-feed {
        display: flex;
        flex-direction: column;

        h4 {
            padding-left: 3rem;
        }
    }

    .container {
        .instagram-feed {
            h4 {
                padding-left: 0;
            }
        }
    }
}


/* ==========================================================================
   Media Queries
   ========================================================================== */

@media (max-width: 991.98px) {}