/* ==========================================================================
   GRID ITEM -- Finish
   ========================================================================== */
   body {
    .tooltip {
      &.show {
        opacity: 1;
      }

      &-inner {
        color: var(--color-accent-1);
        text-align: left;
        background-color: #E3E3E3;
        border-radius: 0;
        padding: 1rem;
      }

      &.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
      &.tooltip.bs-tooltip-bottom .arrow::before {
        border-bottom-color: #E3E3E3;
      }

      &.bs-tooltip-auto[x-placement^=top] .arrow::before,
      &.tooltip.bs-tooltip-top .arrow::before {
        border-top-color: #E3E3E3;
      }

      &.bs-tooltip-auto[x-placement^=left] .arrow::before,
      &.tooltip.bs-tooltip-left .arrow::before {
        border-left-color: #E3E3E3;
      }

      &.bs-tooltip-auto[x-placement^=right] .arrow::before,
      &.tooltip.bs-tooltip-right .arrow::before {
        border-right-color: #E3E3E3;
      }
    }

    .grid-item--finish {
      content-visibility: auto; 
      contain-intrinsic-size: 1px 5000px;
      // margin-bottom: 2rem;
      padding-bottom: 2rem;




      &.col-lg-2 {
        .flip {
          min-height: 400px;

          .grid-item--finish {

            &-summary,
            &-details {
              grid-template-rows: min-content min-content 1fr 2fr;

              &-props {
                padding: 0 1rem;

                p {
                  display: block;

                  i {
                    &.material-icons {
                      margin-right: -5px;
                    }
                  }
                }
              }
            }

            &-summary {
              &-props {

                p {
                  margin-bottom: .5rem;
                }
              }
            }
          }

          &-details {

            &-props {

              p {

                i {
                  margin-bottom: -.3rem;
                }
              }
            }
          }
        }
      }



      .flip {
        // border: 1px solid #f5f5f5; // To see an edge on a white bg
        position: relative;
        width: 100%;
        min-height: 400px;
        transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
        transform-style: preserve-3d;

        .grid-item--finish-details {
          @include animate(all, 1.8, ease);
        }
      }


      &.active {
        .flip {
          transform: rotateY(180deg);

          @extend .shadow; // Add shadow to the active card

          .grid-item--finish-summary,
          .grid-item--finish-details {
            grid-template-rows: auto;
            @include animate(all, 1.8, ease);
          }

          .grid-item--finish {

            &-summary,
            &-details {
              &-swatch {
                padding-top: 15px;
                @include animate(all, 1.8, ease);
              }
            }
          }
        }
      }


      .grid-item--finish-summary,
      .grid-item--finish-details {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        background-color: rgba(255, 255, 255, 1);
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--color-accent-8) #fff;

        &::-webkit-scrollbar {
          width: .15rem;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--color-accent-8);
          outline: 1px solid var(--color-accent-8);
        }


        i {
          margin-right: 1px;
          @include animate(all, .4, ease);

          #{$hover} {
            @include animate(all, .4, ease);
            transform: scale(1.2);
          }


          &.material-icons {
            margin-right: -3px;
          }
        }
      }

      .grid-item--finish {
        // front

        &-summary,
        &-details {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          transition: all 1.8s;

          &-swatch {
            padding-top: 75px;
            transition: all 1.8s;
            cursor: pointer;
            background-size: cover !important;
          }

          &-colour-title {
            padding: 1rem 1rem 0;

            p {
              margin-bottom: .5rem;
            }
          }

          &-props {
            padding: 0 1rem;

            p {
              display: flex;
              align-items: center;
              margin-bottom: 0.5rem;

              i:last-of-type {
                margin-right: .5rem;
              }
            }
          }

          &-hazards {
            margin: 0 1rem;
            padding-top: .5rem;
            padding-bottom: .5rem;

            ul {
              display: flex;
              justify-content: space-between;
              align-items: center;
              list-style: none;
              width: 100%;
              margin: 0;
              padding: 0;
              flex-flow: wrap;

              li {
                display: flex;
                align-items: center;
              }

              a {
                color: $dark;
                text-decoration: underline;

                #{$hover} {
                  color: var(--color-accent-8);
                }
              }
            }
          }

          &-size,
          &-toggle {
            padding: 0 1rem 1rem;
          }

          &-toggle {
            padding-top: 1rem;
            display: flex;
            align-items: center;

            p {
              margin: 0;
              width: 100%;
              @include font-size(10, 4vw, 16);

              &.more,
              &.less {
                cursor: pointer;
              }

              &.more {
                @include arrow(after, -45deg, var(--color-dark));
                display: flex;
                justify-content: space-between;
                align-items: center;

                &::after {
                  height: 10px;
                  margin-right: 0;
                }

              }

              &.less {
                @include arrow(before, 135deg, var(--color-dark));
              }
            }
          }
        }
      }

      .grid-item--finish-summary {
        // back
        grid-template-areas:
          "grid-item--finish-summary-swatch"
          "grid-item--finish-summary-colour-title"
          "grid-item--finish-summary-props"
          "grid-item--finish-summary-size"
          "grid-item--finish-summary-hazards"
          "grid-item--finish-summary-toggle";

        &-swatch {
          grid-area: grid-item--finish-summary-swatch;
        }

        &-colour-title {
          grid-area: grid-item--finish-summary-colour-title;

          p {
            margin-bottom: 0;
          }
        }

        &-props {
          grid-area: grid-item--finish-summary-props;
        }

        &-size {
          grid-area: grid-item--finish-summary-size;
          display: flex;
          align-content: flex-end;
          align-items: flex-end;
          flex-wrap: wrap;

          img {
            margin-top: 5px;
            margin-right: 5px;
            height: fit-content;
          }
        }

        &-hazards {
          grid-area: grid-item--finish-summary-hazards;
          border-top: 1px solid var(--color-accent-4);
          border-bottom: 1px solid var(--color-accent-4);
          display: flex;
          align-items: center;

          p {
            margin-bottom: 0;
            @include font-size(10, 4vw, 13);
          }
        }

        &-toggle {
          grid-area: grid-item--finish-summary-toggle;
        }
      }


      .grid-item--finish-details {
        grid-template-areas:
          "grid-item--finish-details-swatch"
          "grid-item--finish-details-colour-title"
          "grid-item--finish-details-props"
          "grid-item--finish-details-size"
          "grid-item--finish-details-hazards"
          "grid-item--finish-details-toggle";

        transform: rotateY(180deg);

        p {
          strong {
            @include font-size(10, 4vw, 16);
          }

          @include font-size(9, 4vw, 14);
        }

        &-swatch {
          grid-area: grid-item--finish-details-swatch;
        }

        &-colour-title {
          grid-area: grid-item--finish-details-colour-title;
        }

        &-props {
          grid-area: grid-item--finish-details-props;
        }

        &-size {
          grid-area: grid-item--finish-details-size;
        }

        &-hazards {
          grid-area: grid-item--finish-details-hazards;

          p {
            margin-bottom: 0;
            @include font-size(10, 4vw, 13);
          }

          ul {
            justify-content: flex-start;

            li {
              @include font-size(10, 4vw, 13);
              margin-right: .5rem;
              padding-right: .5rem;

              &:last-of-type {
                margin: 0;
                padding: 0;
              }
            }

            &.hazard-icon-titles {
              margin-bottom: .5rem;

              li {
                border-right: 1px solid var(--color-dark);

                &:last-of-type {
                  border: none;
                }
              }
            }
          }
        }

        &-toggle {
          grid-area: grid-item--finish-details-toggle;
        }
      }
    }
  }


  /* ==========================================================================
      Media Queries
      ========================================================================== */
  @media (max-width: 991.98px) {}

  @media (max-width: 575.98px) {
    body {
      .grid-item--finish {

        &.col-lg-2 {
          .flip {
            min-height: 400px;

            .grid-item--finish {

              &-summary,
              &-details {

                &-props {

                  p {
                    display: flex;

                    i {
                      &:last-of-type {
                        margin-right: .5rem;
                      }

                      &.material-icons {
                        margin-right: 1px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  @media (min-width: 575.98px) and (max-width: 991.98px) {
    body {

      .grid-item--finish {

        &.col-md-4 {
          .flip {
            min-height: 400px;

            .grid-item--finish {

              &-summary,
              &-details {
                &-swatch {
                  //padding-top: 75px;
                }

                &-colour-title {
                  padding: .7rem .7rem 0;
                }

                &-props {
                  padding: 0 .7rem;

                  p {
                    display: flex;

                    &.fastness {
                      display: block;

                      span {
                        display: block;
                        width: 100%;
                      }
                    }
                  }
                }

                &-size {
                  padding: 0 .7rem .7rem;
                }

                .grid-item--finish-details-hazards {

                  ul.hazard-icon-titles {
                    li {
                      padding: 0 !important;
                      border: none !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    body {
      .grid-item--finish {
        &.col-lg-2 {
          .flip {
            min-height: 430px;

            .grid-item--finish {

              &-summary,
              &-details {

                &-colour-title,
                &-props {

                  p,
                  strong {
                    @include font-size(9, 4vw, 14);
                    word-break: break-word;
                    display: block;
                  }

                  i.transparency {
                    margin-bottom: -0.3rem;
                  }

                  span {
                    display: inline-block;
                  }

                  p {
                    &.fastness {
                      span {
                        display: block;
                        width: 100%;
                      }
                    }
                  }
                }

                .grid-item--finish-details-hazards {

                  ul.hazard-icon-titles {
                    li {
                      padding: 0 !important;
                      border: none !important;
                    }
                  }
                }
              }

              &-summary {
                &-props {
                  p.transparency {
                    display: flex;
                    padding-top: .3rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }