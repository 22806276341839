/* ==========================================================================
   INSPIRE ME RESULTS STYLES
   ========================================================================== */

body .main-content {
    div.inspire-me-results {
        padding: 0;

        .container-fluid {
            padding-top: 0 !important;
        }

        .config-inspire {
            display: block;
        }
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */