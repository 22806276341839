#paintload-slider {
    .first-slider {
        background-image: url("https://via.placeholder.com/1519x1500.png");
        background-size: cover;
        background-repeat: no-repeat;
        height: 800px;
    }

    .second-slider {
        background-image: url("https://via.placeholder.com/1519x1500.png");
        background-size: cover;
        background-repeat: no-repeat;
        height: 800px;
    }

    .third-slider {
        background-image: url("https://via.placeholder.com/1519x1500.png");
        background-size: cover;
        background-repeat: no-repeat;
        height: 800px;
    }
}

.slider-container {
    max-width: 350px;
    background-color: #f0ebe3;
    padding: 40px 32px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 110px;
}

.slider-title {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.8px;
    background-color: #ff0089;
    padding: 10px;
    transform: rotate(0.5deg);
    color: #fff;
    font-weight: bold;
    margin-bottom: 27px;
}

.slider-subtitle {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: -0.48px;
}

.slider-desc>p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
}

#paintload-slider .carousel-indicators {
    margin-bottom: 50px;
    z-index: 20;
}

#paintload-slider .carousel-indicators li {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    text-indent: -999px !important;
    cursor: pointer !important;
    background-color: #fff !important;
    opacity: 1 !important;
}

#paintload-slider .carousel-indicators li.active {
    background-color: #FF0089 !important;
}

#paintload-slider .carousel-inner .carousel-item {
    height: 650px !important;
}

.slider-link {
    font-size: 18px;
    line-height: 60px;
    color: #000;
    background-color: #fff;
    padding: 7px 14px;
}

@media (max-width:767px) {
    .slider-container {
        margin-top: 70px;
    }
    #paintload-slider .carousel-inner .carousel-item {
        height: 700px !important;
    }
}

@media (max-width:990px) {
    .slider {
        position: relative;
    }

    .slider-arrow {
        position: absolute;
        left: 75%;
        bottom: -30px;
        cursor: pointer;
        z-index: 19;
    }

    .slider-top-image {
        position: absolute;
        z-index: 17;
        left: 0;
    }

    .slider-bottom-image {
        position: absolute;
        z-index: 18;
        bottom: -42px;
        width: 100%;
        height: 100px;
    }

    #paintload-slider .carousel-indicators {
        margin-bottom: 0px !important;
        bottom: 50px;
    }
}

@media (min-width:991px) {
    .slider {
        position: relative;
    }

    .slider-container {
        max-width: 490px;
        background-color: rgba(240, 235, 227, 0.9);
        padding: 64px 40px 40px;
        margin-left: auto;
        margin-right: 306px;
        margin-top: 100px;

        .slider-title {
            margin-bottom: 32px;
        }

    }

    .slider-arrow {
        position: absolute;
        left: 60%;
        bottom: -37px;
        cursor: pointer;
        z-index: 16;
    }

    .slider-top-image {
        position: absolute;
        z-index: 17;
        left: 25%;
        bottom: -72px;
    }

    .slider-bottom-image {
        position: absolute;
        z-index: 18;
        bottom: -44px;

    }
    #paintload-slider .carousel-inner .carousel-item .slider-container:nth-child(2) .slider-title {
        max-width: 320px;
    }
    #paintload-slider .carousel-inner .carousel-item .slider-container:nth-child(3) .slider-title {
        max-width: 215px;
    }
}