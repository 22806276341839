/* ==========================================================================
   NAV
   ========================================================================== */

body {
    header#primary-nav-warpper {
        background: var(--color-accent-7);
        position: fixed;
        z-index: 3;
        width: 100%;
        .container>.col {
            padding: 0;
        }
    }
    #nav-main {
        padding: 1rem 0;
        a {
            outline: 0;
            &.navbar-brand {
                width: 100px;
                img {
                    width: 100px;
                    @include animate(2);
                }
            }
        }
        .icon-bar {
            width: 22px;
            height: 2px;
            background-color: #B6B6B6;
            display: block;
            transition: all 0.2s;
            margin-top: 4px
        }
        .navbar-toggler {
            border: none;
            background: transparent !important;
            .top-bar {
                transform: rotate(45deg);
                transform-origin: 10% 10%;
            }
            .middle-bar {
                opacity: 0;
            }
            .bottom-bar {
                transform: rotate(-45deg);
                transform-origin: 10% 90%;
            }
            &.collapsed {
                .top-bar {
                    transform: rotate(0);
                }
                .middle-bar {
                    opacity: 1;
                }
                .bottom-bar {
                    transform: rotate(0);
                }
            }
        }
        ul {
            &.list-col-3~.list-col-3 {
                li {
                    page-break-inside: avoid;
                    break-inside: avoid-column;
                    a {
                        border-bottom: 2px solid var(--color-accent-5);
                    }
                }
            }
            li {
                @include font-size(10, 4vw, 16);
                a {
                    color: var(--color-dark);
                    padding-right: .75rem;
                    padding-left: .75rem;
                    &.dropdown-toggle::after {
                        display: none;
                    }
                    &#navbarDropdownSearch {
                        font-size: 22px;
                        margin-top: -4px;
                        padding-bottom: 0;
                    }
                }
                .dropdown-menu {
                    .mega-intro {
                        @include font-size(11.5, 4vw, 16);
                        font-weight: 600;
                        span {
                            font-family: 'LuckyFellas';
                            font-size: 2.5rem;
                            font-weight: 100;
                            line-height: 2rem;
                        }
                    }
                    p {
                        @include font-size(8, 4vw, 14);
                    }
                }
            }
        }
    }
}


/* ==========================================================================
   Media Queries
   ========================================================================== */

@media (max-width: 991.98px) {
    body #nav-main ul li a:not(.btn) {
        padding-left: 0;
    }
}

// MOBILES
@media (max-width: 575.98px) {
    body header#primary-nav-warpper {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 100vh;
        z-index: 16;
    }
    #nav-main {
        .navbar-brand {
            margin-right: 48%;
        }
        .dropdown-menu {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid var(--color-accent-6);
            box-shadow: none !important;
            padding: 0;
            margin-bottom: 25px;
            .container {
                padding: 2rem 0;
            }
            .list-col-3 {
                column-count: 2;
                padding: 0;
                list-style: none;
                li {
                    padding: 0;
                    margin-bottom: 15px;
                    a {
                        padding: 0;
                    }
                }
            }
        }
        .menu {
            display: flex;
            flex-direction: column;
            a {
                display: flex;
                align-items: center;
                height: 50px;
                border-bottom: 2px solid #f5f5f5;
                max-width: unset !important;
                @include animate(0.5);
                &.active {
                    text-decoration: none !important;
                    i {
                        transform: rotate(90deg);
                        @include animate(0.5);
                    }
                }
            }
            .submenu {
                display: flex;
                flex-direction: column;
                width: 100vw;
                margin-left: -15px;
                padding: 0 30px;
                height: 0;
                overflow: hidden;
                a {
                    text-decoration: none !important;
                    max-width: unset;
                }
            }
            .active+.submenu {
                background-color: var(--color-accent-1--lighten-p80);
                height: 100%;
                padding-bottom: 20px;
                .active+.submenu {
                    background-color: var(--color-accent-1--lighten-p95);
                    margin-left: -30px;
                    padding: 0 60px 20px;
                }
            }
        }
        .store-locator {
            .bg-dark {
                width: 100vw;
                margin: 15px 0 -16px -15px;
                padding: 0 15px !important;
                border-bottom: 3px solid var(--color-light);
                form {
                    label {
                        border: none;
                        padding-left: 0;
                        &[for='searhbarpostalcode'] {
                            display: none;
                        }
                    }
                    #searhbarpostalcode {
                        margin-left: 0;
                    }
                }
            }
        }
        .mobile-search {
            width: 100vw;
            margin: 15px 0 -16px -15px;
            padding-left: 0;
            padding-right: 15px;
            background: var(--color-dark);
            label {
                display: none;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    #nav-main {
        ul.navbar-nav {
            justify-content: space-around;
            flex-direction: row;
        }
    }
}

// Tablets & Desktops
@media (min-width: 575.98px) {
    #nav-main {
        @include animate(2);
        .navbar-brand {
            margin-right: 70%;
        }
        .navbar-toggler:not(.search) {
            margin-top: -.4rem;
        }
        ul.navbar-nav {
            display: flex;
            width: 100%;
            margin-top: .5rem;
            margin-left: 0;
            >li {
                &.search,
                &.store-locator {
                    .dropdown-menu {
                        padding: 0;
                        border: none;
                        border-radius: 0;
                    }
                }
                .btn {
                    color: var(--color-light);
                    margin-top: 1.5rem;
                    float: left;
                    clear: both;
                    border-bottom: none;
                }
                .dropdown-menu {
                    height: 0;
                    opacity: 0;
                    overflow: hidden;
                    @include animate(1);
                    &.show {
                        opacity: 1;
                        @include animate(1);
                    }
                    ul {
                        margin: 0;
                        li {
                            &:nth-last-child(2) {
                                text-align: left;
                            }
                        }
                        &.list-col-3 {
                            padding: 0;
                            list-style: none;
                            li a {
                                padding-left: 0;
                            }
                        }
                    }
                    h5 {
                        font-weight: 600;
                    }
                }
            }
        }
        .menu {
            // overflow: scroll;
            position: relative;
            width: 100%;
            a {
                float: left;
                clear: both;
                display: flex;
                align-items: center;
                align-content: space-between;
                min-width: 250px;
                border-bottom: 2px solid var(--color-accent-5);
                padding-bottom: 5px;
                margin: 3px 5px 5px 5px;
                text-decoration: none;
                color: var(--color-accent-1);
                line-height: 24px;
                &.btn {
                    min-width: 0;
                }
                &+div,
                i {
                    width: 0;
                    opacity: 0;
                    @include animate(0.5);
                }
                &.active {
                    text-decoration: none !important;
                    i {
                        width: 24px;
                        text-decoration: none;
                        opacity: 1;
                        @include animate(0.5);
                    }
                }
                &.active+div {
                    @include animate(0.5);
                    width: 100%;
                    opacity: 1;
                    z-index: 0;
                    a {
                        &.active+div {
                            width: 100%;
                            opacity: 1;
                            z-index: 0;
                            @include animate(0.5);
                        }
                    }
                }
            }
            .submenu {
                position: absolute;
                top: 0;
                left: 275px;
                z-index: -20;
            }
        }
    }
    .navbar-expand-lg .navbar-nav .dropdown {
        position: static !important;
        &:not(.search),
        &:not(.store-locator) {
            .dropdown-menu.show {
                padding: 2rem 0;
                //min-height: 650px;
                min-height: 725px;
                max-height: 95vh;
                @include animate(1);
            }
        }
        &.search,
        &.store-locator {
            .dropdown-menu.show {
                height: 87px !important;
                min-height: unset;
                @include animate(1);
            }
        }
        .dropdown-menu {
            position: absolute;
            width: 100vw !important;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            margin-top: -1px;
            border-radius: 0;
            border: none;
            display: block;
            padding: 0;
        }
    }
    body.scrolling {
        #nav-main {
            @include animate(2);
            padding: 0;
            ul {
                margin-top: 0;
            }
            a.navbar-brand {
                @include animate(2);
                width: 70px;
                padding-bottom: 0.5rem;
                img {
                    @include animate(2);
                    width: 70px;
                }
            }
        }
    }
}

// MOBILE & TABLETS
@media (max-width: 768px) {
    body {
        .navbar-nav {
            margin-top: 2rem;
            max-width: 91vw;
            & li>a {
                line-height: 30px;
            }
        }
        #nav-main {
            .navbar-toggler.search {
                display: flex;
                padding: 0;
            }
            ul li a {
                &:not(.navbar-brand):not(.btn) {
                    border-bottom: 2px solid #f5f5f5;
                }
                &.btn {
                    margin-top: 2rem;
                    color: var(--color-light);
                    justify-content: center;
                }
            }
            .dropdown-menu {
                ul.list-col-3 {
                    li {
                        a {
                            border: none;
                        }
                    }
                }
            }
            .menu {
                .submenu {
                    left: 230px;
                    overflow-y: scroll;
                }
                a {
                    min-width: 220px;
                    max-width: 220px;
                }
            }
        }
    }
    .aux {
        a {
            text-transform: uppercase !important;
        }
    }
}

// DESKTOPS
@media (min-width: 992px) {
    #nav-main {
        @include animate(2);
        .navbar-brand {
            margin-right: 1rem;
        }
        a.close-dropdown {
            position: absolute;
            right: 20px;
            z-index: 1;
        }
        .dropdown-menu {
            .container {
                height: 100%;
                .row {
                    height: 100%;
                    >.col-lg-3 {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        p:not(.mega-intro) {
                            flex-grow: 1;
                        }
                        img {
                            margin-bottom: -32px;
                        }
                    }
                }
            }
        }
        ul.navbar-nav {
            display: flex;
            width: 100%;
            margin-top: .5rem;
            margin-left: 2rem;
            >li {
                &:nth-last-child(2) {
                    flex: 1 1 auto;
                    text-align: right;
                    a {
                        display: inline-block;
                        span {
                            border: none;
                        }
                    }
                }
                &.aux {
                    @include font-size(8, 4vw, 14);
                    a {
                        color: var(--color-accent-1--lighten-p50);
                        padding-right: .5rem;
                        padding-left: .5rem;
                        span {
                            display: inline-block;
                            padding-left: 15px;
                        }
                    }
                    &.dropdown {
                        a span {
                            border-left: 1px solid var(--color-accent-1--lighten-p50);
                        }
                    }
                }
            }
        }
    }
}