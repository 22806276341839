/* ==========================================================================
   SEARCH STYLES
   ========================================================================== */
form.search {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-between;
    padding: 1rem 0;

    .form-group {
        margin-bottom: 0;
    }

    label {
        @include font-size(14, 4vw, 24);
        color: var(--color-accent-7);
        flex: 0 1 auto;
    }

    input {
        flex: 1 1 0;
        margin: 0 1rem;
    }

    input,
    .btn {
        border-radius: 50px !important;
        border-color: transparent;
    }

    .col-md-2 {
        padding: 0;
    }

    .btn {
        width: 40px;
        height: 40px;
        padding-left: .5rem;
        @include font-size(14, 4vw, 24);
        color: var(--color-dark) !important;
        margin: 0 !important;
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
// Odd flexbox sizing for Firefox so I have targetted it & unset it
// https://www.ryadel.com/en/css3-media-query-target-only-ie-ie6-ie11-firefox-chrome-safari-edge/#Firefox
@-moz-document url-prefix() {
    @supports (animation: calc(0s)) {
        form.search {
            input {
                width: 75%;
                flex: unset;
            }
        }
    }
}