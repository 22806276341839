.our-vision {
    .our-vision-top-image {
        margin-top: -35px;
    }

    .our-vision-title {
        color: #fff;
        letter-spacing: -0.8px;
        font-size: 40px;
        line-height: 40px;
        font-weight: bold;
        position: relative;
        margin-top: 35px;
        margin-bottom: 12px;
    }

    .our-vision-title::before {
        background-color: #006DD9;
        content: "";
        height: 47px;
        width: 235px;
        position: absolute;
        z-index: -1;
        left: -10px;
        top: -5px;
        transform: rotate(-2deg);
    }

    .our-vision-subtitle>p {
        font-size: 23px;
        line-height: 30px;
        letter-spacing: -0.48px;
        color: #030209;
    }

    .our-vision-desc>p {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0px;
        color: #040404;
    }

    .our-mission-title {
        color: #fff;
        letter-spacing: -0.8px;
        font-size: 40px;
        line-height: 40px;
        font-weight: bold;
        margin-top: 35px;
        margin-bottom: 12px;
        position: relative;
    }

    .our-mission-title::before {
        background-color: #00BEA8;
        content: "";
        height: 47px;
        width: 265px;
        position: absolute;
        z-index: -1;
        left: -10px;
        top: -5px;
        transform: rotate(2deg);
    }
}

@media (max-width:990px) {
    .our-vision {
        position: relative;

        .our-vision-arrow {
            position: absolute;
            left: 15px;
            z-index: 9;
            bottom: -80px;
            cursor: pointer;
        }
        .our-vision-title {
            margin-bottom: 28px;
        }
        .our-mission-title {
            margin-bottom: 28px; 
        }
        .our-mission-title::before {
            top: -8px;
        }
    }

    .our-vision-title {
        left: 11px !important;
        margin-left: 0px !important;
    }

    .our-mission-title {
        left: 11px !important;
        margin-left: 0px !important;
    }
}

@media (min-width:991px) {
    .our-vision {
        position: relative;

        .our-vision-top-image {
            margin-top: -44px;
        }

        .our-vision-container {
            margin-bottom: 40px;
        }

        .our-vision-subtitle>p {
            font-size: 30px;
            line-height: 35px;
            letter-spacing: -0.6px;
            color: #030209;
            max-width: 460px;
            margin-bottom: 0px;
        }

        .our-vision-desc>p {
            max-width: 460px;
            margin-top: 16px;
        }

        .our-vision-arrow {
            position: absolute;
            left: 18%;
            z-index: 9;
            bottom: -70px;
            cursor: pointer;
        }
    }
    
}