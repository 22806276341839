.paintload-projects {
    background-color: #000000;
    margin-top: 50px;
}

.paintload-projects-container {
    .projects-title {
        font-size: 40px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -.8px;
        text-transform: uppercase;
        color: #fff !important;
        margin-top: 25px;
        position: relative;
        z-index: 1;
    }

    .projects-title::before {
        content: "";
        display: block;
        position: absolute;
        background-color: #ff0089;
        width: 195px;
        height: 58px;
        z-index: -1;
        top: -9px;
        left: -10px;
        transform: rotate(-1deg);
    }

    .paintload-projects-desc>p {
        margin-top: 25px;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0px;
        color: #F2FFFF !important;
        margin-bottom: 32px;
    }

    .paintload-projects-btn-desk {
        display: none;
    }
}

.paintload-projects-images-container {
    .paintload-projects-img-title {
        font-size: 20px;
        margin-top: 10px;
        line-height: 30px;
        letter-spacing: -0.4px;
        color: #fff;
        text-align: center;
        margin-bottom: 24px;
    }

    .paintload-projects-btn-mob {
        background-color: #fff;
        padding: 8px 20px;
        margin: 40px auto 40px;
        display: flex;
        color: #000 !important;
        max-width: 175px;
    }
}

.paint-load {
    display: none;
}
.paintload-page .paintload-projects-btn-desk:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
	color: #fff !important;
}
@media (max-width:767px) {
    .paintload-projects-img {
        width: 100%;
    }
}

@media (max-width:990px) {
    .paintload-projects-arrow {
        position: relative;
        right: -85%;
        top: 0;
        z-index: 9;
        cursor: pointer;
    }

    .projects-title {
        left: 10px !important;
    }
    .paintload-projects-hero-img {
        margin-bottom: 25px;
    }
}

@media (min-width:991px) {
    .paintload-projects {
        position: relative;
        margin-top: 40px;

        .paint-load {
            position: absolute;
            top: 41%;
            display: block;
        }
    }

    .paintload-projects-container {
        .paintload-projects-btn-desk {
            display: block;
            margin-top: 40px;
            background-color: #fff;
            padding: 0px 16px;
            margin-bottom: 36px;
            max-width: 176px;
            color: #000 !important;
            font-size: 18px;
            line-height: 40px;
            letter-spacing: -0.36px;
            transition: all ease 500ms;
        }

    }
    .rotate-image {
        position: relative;
    }
    // .rotate-image::before {
    //     display: block;
    //     background-color: #00BEA8;
    //     content: "";
    //     width: 350px;
    //     height: 181px;
    // }
    .paintload-projects-images-container .col-lg-6.col-12:nth-child(1) .rotate-images::before {
        display: block;
        background-color: #00ff58;
        content: "";
        width: 350px;
        height: 181px;
    }
    .paintload-projects-images-container .col-lg-6.col-12:nth-child(2) .rotate-images::before {
        display: block;
        background-color: #FF0089;
        content: "";
        width: 350px;
        height: 181px;
    }
    .paintload-projects-images-container .col-lg-6.col-12:nth-child(3) .rotate-images::before {
        display: block;
        background-color: #FF0089;
        content: "";
        width: 350px;
        height: 181px;
    }
    .paintload-projects-images-container .col-lg-6.col-12:nth-child(4) .rotate-images::before {
        display: block;
        background-color: #006DD9;
        content: "";
        width: 350px;
        height: 181px;
    }
    .paintload-projects-images-container .col-lg-6.col-12:nth-child(5) .rotate-images::before {
        display: block;
        background-color: #00BEA8;
        content: "";
        width: 350px;
        height: 181px;
    }
    .paintload-projects-images-container .col-lg-6.col-12:nth-child(6) .rotate-images::before {
        display: block;
        background-color: #FF5800;
        content: "";
        width: 350px;
        height: 181px;
    }
    .paintload-projects-img {
        position: absolute;
        top: 0;
        
    }
    .rotate-images:hover::before {
        transform: rotate(5deg);
    }
    .paintload-projects .container {
        padding-top: 82px;
    }

    .paintload-projects-hero-img {
        position: relative;
    }

    .paintload-projects-top-image {
        position: absolute;
        right: 0;
    }

    .paintload-projects-images-container {
        margin-top: 25px;
        padding-bottom: 99px;

        .paintload-projects-btn-mob {
            display: none;
        }
    }

    .paintload-projects-img:hover {
        transform: rotate(-3deg);
    }

    .paintload-projects-arrow {
        position: relative;
        right: -65%;
        top: 30px;
        z-index: 9;
        cursor: pointer;
    }
}

body a:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
    color: unset !important;
    text-decoration: unset !important;
}

.paintload-page .paintload-projects-btn-desk:hover {
    background-color: #FF0089 !important;
    color: #fff !important;
    transform: rotate(2deg);
}