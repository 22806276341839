/* ==========================================================================
   SIGNPOSTS STYLES
   ========================================================================== */
.signpost {
    content-visibility: auto; 
    contain-intrinsic-size: 1px 5000px;
    display: block;

    #{$hover} {
        text-decoration: none !important;

        .btn {
            background-color: var(--color-link-hover);
            border-color: var(--color-accent-8--darken-p10);
            color: var(--color-light);
        }
    }

    .d-flex {
        height: 600px;
        max-height: 90vh;
    }


    &--standard,
    &--quote {
        // h2 {
        //     @include font-size(36, 7vw, 70, 0);
        //     @include extra-bold(normal);
        //     text-transform: uppercase;
        //     margin-bottom: 20px;
        //     margin-left: -5px;
        // }

        p {
            @include font-size(14, 4vw, 18, 8);

            &:first-of-type {
                text-transform: uppercase;
                @include font-size(19, 4vw, 23, 0);
            }
        }

        &--image-right {}

        &--image-left {}
    }

    &--standard {

        &--image-right {}

        &--image-left {}
    }

    &--quote {

        blockquote {

            // padding-top: 30px;

            &::before,
            &::after {
                width: 30px;
                height: 60px;
                display: flex;
                @include font-size(105, 4vw, 110, 0);
                @include bold(normal);
                font-family: 'Helvetica Neue',
                    'Hind',
                    'Open Sans',
                    Arial,
                    sans-serif;
                ;
            }

            &::before {
                content: "\201C";
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 10px;
                margin-left: -5px;
            }

            &::after {
                content: "\201D";
                justify-content: flex-start;
                align-items: center;
                margin-top: 20px;
            }

            p {
                @include bold(normal);
                text-transform: uppercase;
                @include font-size(14, 4vw, 18, 8);

            }
        }

        &--image-right {}

        &--image-left {}
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */