/* ==========================================================================
   CAROUSEL MULTI
   ========================================================================== */
body {
    // There is some jquery that controls things too see carousel-multi.html

    .carousel-multi {

        .carousel-item {
            padding: 50px 0;
        }
    }
}


/* ==========================================================================
   Media Queries
   ========================================================================== */

@media (min-width: 576px) {
    body {
        .carousel-multi {
            .carousel-inner {

                .carousel-item.active,
                .carousel-item-next,
                .carousel-item-prev {
                    display: flex;
                }

                .carousel-item-right.active,
                .carousel-item-next {
                    transform: translateX(33%);
                }

                .carousel-item-left.active,
                .carousel-item-prev {
                    transform: translateX(-33%);
                }

                .carousel-item-right,
                .carousel-item-left{
                    transform: translateX(0);
                }
            }
        }
    }
}