/* ==========================================================================
   INSPIRE ME STYLES
   ========================================================================== */

.inspire-me {

    .intro {
        @include font-size(20, 4vw, 24);
    }

    .lucky-fellas {
        font-size: 3.5rem;
        font-weight: 100;
        line-height: 2rem;
        margin-right: 10px;
    }

    form.user-choices {

        .user- {

            &materials,
            &techniques,
            &surfaces,
            &details,
            &email-signup {
                height: 0;
                overflow: hidden;
                @extend .animate;
            }

            &needs,
            &materials,
            &techniques,
            &surfaces,
            &details{

                input {

                    &[type=radio],
                    &[type=checkbox] {
                        display: none;
                    }
                }
            }

            @at-root .inspire-me form.user-choices .user-details input#inspire-ud-email-me {
                display: block;
            }

            &email-signup {
                label:not(.form-check-label) {
                    @include font-size(26, 4vw, 30);
                }

                input {
                    &[type=text],
                    &[type=email] {
                        height: calc(1.5em + 1.4rem + 2px);
                        border-radius: 0;
                        border: none;
                        background: #F5F5F5;
                    }

                    &.btn {
                        padding: .7rem .75rem .4rem;
                        font-weight: 600;
                        font-size: 1.1rem;
                    }
                }
            }

            &details {

                .alert-area {
                    height: 0;
                }

                .alert {
                    display: flex;
                    flex-direction: column;
                    border: none;
                    border-radius: 0;
                    padding: 3rem;

                    strong {
                        @include font-size(20, 4vw, 26);
                        font-weight: 400;
                        margin-bottom: 1.5rem;
                    }
                }
            }
        }

        label {
            cursor: pointer;

            &.user-needs {
                @extend .animate;
                background: var(--color-accent-5);
                padding: 20px;
                display: flex;
                align-items: center;
                @include font-size(16, 4vw, 19);

                span {
                    flex: 1 0;
                }

                i {
                    color: var(--color-accent-6--lighten-p40);
                }

                // &:hover,
                // &:focus,
                // &:active,
                &.active {
                    @extend .animate;
                    background: var(--color-dark);
                    color: var(--color-accent-7);

                    i {
                        color: var(--color-accent-7);
                    }
                }


                &.email,
                &.just-save {
                    align-items: flex-start;
                    padding: 3rem;

                    span {
                        font-weight: 500;
                        @include font-size(20, 4vw, 24);

                        span {
                            margin-top: 2rem;
                            display: block;
                            @include font-size(16, 4vw, 18);
                        }
                    }
                }
            }


            &.user-materials,
            &.user-techniques,
            &.user-surfaces {
                text-align: center;
                @extend .animate;

                img {
                    margin: 1rem 0;
                }

                i {
                    @include font-size(20, 4vw, 24);
                    color: var(--color-accent-6--lighten-p40);
                }

                // &:hover,
                // &:focus,
                // &:active,
                &.active {
                    @extend .animate;

                    i {
                        color: var(--color-accent-8);
                    }
                }

            }
        }
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */