/* ==========================================================================
   TIMELINE
   ========================================================================== */
body {

    //Timeline

    .timeline-navarea {
        display: flex;
        justify-content: space-between;
        margin: 1.375rem 0 1rem;

        button {
            &.slick {
                &-arrow {
                    color: transparent;
                    font-size: 0;
                    width: 14px;
                    height: 24px;
                    border: none;
                    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24' viewBox='0 0 14 24'%3E%3Cpath d='M15.321,18l8.937-8.93a1.688,1.688,0,0,0-2.391-2.384L11.742,16.8a1.685,1.685,0,0,0-.049,2.327L21.86,29.32a1.688,1.688,0,0,0,2.391-2.384Z' transform='translate(-11.251 -6.194)'/%3E%3C/svg%3E") no-repeat;

                    &:hover {
                        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.503' height='23.619' viewBox='0 0 13.503 23.619'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%2300793c%3B%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M15.321 18l8.937-8.93a1.688 1.688 0 0 0-2.391-2.384L11.742 16.8a1.685 1.685 0 0 0-.049 2.327L21.86 29.32a1.688 1.688 0 0 0 2.391-2.384Z' transform='translate(-11.251 -6.194)'/%3E%3C/svg%3E") no-repeat;

                    }
                }

                &-next {
                    transform: rotate(180deg);
                }
            }

        }
    }

    .timeline-carousel {
        position: relative;
        overflow: hidden;

        .slick-list {
            overflow: visible;
        }

        h1 {
            color: var(--color-accent-1);
            font-size: 46px;
            line-height: 50pd;
            margin-bottom: 41px;
            font-weight: 900;
        }

        &__image {
            padding-right: 30px;
        }

        &__item {
            cursor: pointer;

            .media-wrapper {
                opacity: 0.4;
                padding-bottom: 71.4%;
                transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);

                &--video {
                    padding: 7.7% 0;
                    background-color: var(--color-dark);
                }
            }
        }

        &__item-inner {
            position: relative;
            padding-top: 45px;

            &:after {
                position: absolute;
                width: 100%;
                top: 45px;
                left: 0;
                content: "";
                border-bottom: 1px solid var(--color-accent-8);
                width: calc(100% - 30px);
            }

            .year {
                font-size: 36px;
                line-height: 36px;
                color: var(--color-dark);
                display: table;
                letter-spacing: -1px;
                padding-right: 10px;
                z-index: 1;
                position: relative;
                margin: -15px 0 20px;
                font-weight: 500;
                background: var(--color-light);

                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    left: -10px;
                    top: 0;
                    height: 100%;
                    width: 10px;
                    background-color: var(--color-light);
                    z-index: 3;
                }
            }

            .month {
                font-size: 16px;
                text-transform: uppercase;
                // color: var(--color-dark);
                display: block;
                margin-bottom: 10px;
                font-weight: 900;
            }

            p {
                width: 80%;
            }


            .pointer {
                height: 29px;
                position: relative;
                z-index: 1;
                margin: -4px 0 16px;

                &:after,
                &:before {
                    position: absolute;
                    content: "";
                }

                &:after {
                    width: 9px;
                    height: 9px;
                    border-radius: 100%;
                    top: 0;
                    left: 0;
                    background-color: var(--color-dark);
                }

                &:before {
                    width: 1px;
                    height: 100%;
                    top: 0;
                    left: 4px;
                    background-color: var(--color-dark);
                }
            }
        }

        .slick-active {
            .media-wrapper {
                opacity: 1 !important;
            }
        }
    }
}

@media (max-width: 767px) {
    .timeline-carousel {
        .carousel.img {
            height: auto !important;
        }
    }
}

// TABLETS
@include media-breakpoint-only(md) {
    body .timeline-carousel__item-inner p {
        width: 96%;
    }
}

@media (min-width: 991px) {
    .timeline-carousel {
        .carousel.img {
            height: auto !important;
            min-height: 375px;
            min-width: 525px;
        }
    }
}