.store-locator .search.store {
	display: none;
}

.store-locator .col-12.col-md-6.col-lg-5.col-xl-4 .search.current-location {
	display: none;
}

.addresses-list {
	background-color: #F5F5F5;
	padding: 10px 50px 10px 14px;
	width: 100%;
	margin-bottom: 10px;
}

.addresses-list:hover {
	background-color: #008344;
	color: #fff !important;
}

.store-locator .title-page {
	font-size: calc(1.325rem + .9vw);
	margin-bottom: 2rem;
}

.address-link {
	color: #030209;
	font-size: 16px;
	line-height: 24px;
	display: inline-block;
	font-weight: 700;
	letter-spacing: -0.32px;
}

.addresses-list:hover>div>.address-link {
	color: #fff !important;
}

body .address-link:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
	color: #fff !important;
	text-decoration: none !important;
}

.addresses-list p {
	margin-bottom: 5px !important;
}

.address {
	font-size: 16px;
	line-height: 24px;
	color: #030209;
	font-weight: 300;
	cursor: pointer;
	letter-spacing: -0.32px;
	margin-bottom: 0;
}

.address:hover {
	color: #fff;
}

.addresses-list:hover>div>.address {
	color: #fff !important;
}

.address-email {
	font-size: 16px;
	color: #008344;
	font-weight: 200;
}

p>.address-email:hover {
	color: #fff !important;
}

.addresses-list:hover>div>p>.address-email {
	color: #fff !important;
}

body .address-email:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
	// color: #fff !important;
	text-decoration: none !important;
}

.addresses {
	margin-top: 37px;
}
.store-locator .simplebar-mask {
	margin-top: 0px;
}

//   /* Firefox */
//   * {
//     scrollbar-width: thin;
//     scrollbar-color: #e4caff #ffffff;

//   }

//   /* Chrome, Edge, and Safari */
//   *::-webkit-scrollbar {
//     width: 20px;
//   }

//   *::-webkit-scrollbar-track {
//     background: #ffffff;
//   }

//   *::-webkit-scrollbar-thumb {
//     background-color: #e4caff;
//     border-radius: 25px;
//     border: 15px solid #eeeeee;
//   }

div.store-locator #map {
	border: unset;
}

.addresses li::before {
	display: none !important;
}

div.store-locator form.search {
	margin-top: 2rem !important;
}

div.store-locator form.search input {
	width: 100%;
}

.country.collapse:not(.show) {
	display: block;
}

.country::after {
	position: absolute;
	border: solid #0000004D;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 5px;
	content: "";
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	right: 15px;
	transition: all 300ms ease-in;
}

.country {
	margin-top: 10px;
	position: relative;
	cursor: pointer;
	font-weight: 600;
	color: #030209;
}

.languages {
	border-top: 1px solid rgba(0, 0, 0, .1);
	cursor: pointer;
}

// .dropdown-container {
// 	border-bottom: 1px solid rgba(0,0,0,.1);
// 	padding-bottom: 27px;
// }
.country-container {
	width: 100%;
	margin-top: 58px;
	display: none;
}

.country-list li::before {
	list-style: none !important;
	content: "" !important;
	padding-right: 0px !important;
}

.country-list li {
	font-size: 20px !important;
	line-height: 30px !important;
	color: #000;
	letter-spacing: -.4px;
	cursor: pointer;
	margin-bottom: 5px !important;
}

.mapboxgl-canvas {
	width: 100%;
}

.country-container {
	padding-left: 15px;
}

.retailers-online h2 {
	font-size: 30px !important;
	font-weight: 300 !important;
	line-height: 24px !important;
	letter-spacing: -0.6px !important;
	color: #030209;
}

.retailers-online {
	padding-left: 0;
	padding-right: 0;
}

.country.arrow-rotate::after {
	transform: rotate(-135deg);
	margin-top: 5px;
	transition: all 300ms ease-in;
}

.retailers-online h2 {
	margin: 50px 15px 0;
}

.fa.fa-search {
	position: absolute;
	right: 15px;
	top: 9px;
	color: #676767;
	font-size: 18px;
}

.search.current-location {
	position: relative;
}

#app .col-lg-4.col-12 {
	padding-left: 0;
	padding-right: 0;
}

#app .col-lg-8.col-12 {
	padding-left: 0;
	padding-right: 0;
}

#app .col-12.search-input {
	padding-left: 0;
	padding-right: 0;
}

.error-msg-title {
	margin-top: 61px;
	font-size: 18px;
	line-height: 30px;
	letter-spacing: -0.36px;
	color: #030209;
	font-weight: 600;
	margin-bottom: 32px;
}

.retailers-online ul>li:first-child {
	margin-top: 0;
}

.retailers-online ul li {
	margin: 0 !important;
	font-size: 18px !important;
	line-height: 30px !important;
	letter-spacing: -0.36px;
	font-weight: 300;
}
.counrty-name:nth-child(2n) {
	text-align: left;
	justify-content: left;
}
.simplebar-track.simplebar-vertical {
	opacity: 0;
}
.results-loadmore {
	background-color: #f5f5f5;
	padding: 10px 20px;
	color: #000 !important;
	border-radius: 4px;
	display: flex;
	max-width: 125px;
	margin-left: auto;
	margin-right: auto;
	justify-content: center;
}
body .results-loadmore:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
	color: #fff !important;
}
.results-loadmore:hover {
	background-color: #008344;
}
.page-refresh {
	background-color: #008344;
	padding: 10px 20px;
	color: #FFF !important;
	border-radius: 4px;
	display: block;
	max-width: 270px;
	margin-top: 30px;
	text-align: center;
  }
  body .page-refresh:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
  color: #fff !important;
  }
  .page-refresh:hover {
	background-color: #008344;
}
.error-msg-description {
	margin-top: 32px;
	font-size: 18px;
	line-height: 30px;
	letter-spacing: -0.36px;
	color: #030209;
	// font-weight: 600;
	margin-left: -15px;
	margin-right: -15px;
}
@media (min-width:991px) {
	
	.error-msg-description {
		margin-top: 60px;
		margin-left: unset;
		margin-right: unset;
	}
	.store-locator .col-8,
	.store-locator .col-12 {
		padding-left: 0px !important;
	}

	.error-msg-title {
		margin-bottom: 50px;
	}

	#app .col-lg-4.col-12 {
		padding-left: 15px !important;
		padding-right: 0;
	}

	#app .col-lg-8.col-12 {
		padding-left: 0;
		padding-right: 0;
	}

	#app .col-12.search-input {
		padding-left: unset;
		padding-right: unset;
	}

	.store-locator .title-page {
		font-size: 50px !important;
		line-height: 55px;
		letter-spacing: -1px;
	}

	.retailers-online h2 {
		margin: 50px 0px 0 !important;
	}

	.retailers-online ul {
		margin: 20px -15px !important;
	}

	.country-container {
		padding-left: 0px;
	}

	.map-list-container {
		border-bottom: 1px solid rgba(0, 0, 0, .1);
		padding-bottom: 50px;
	}

	.dropdown-container {
		border-bottom: 1px solid rgba(0, 0, 0, .1);
		padding-bottom: 27px;
	}

	.addresses {
		margin-top: 0px;
	}
	.store-locator .simplebar-mask {
		margin-top: 37px;
	}

	.addresses-list {
		width: auto;
	}
	#map-scroll-bar {
		height: 460px;
	}

	.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
		top: 37px !important;
	}
	.simplebar-track.simplebar-vertical {
		opacity: 1;
	}
	.simplebar-scrollbar::before {
		background: #6E6F6F !important;
		opacity: 1 !important;
	}

	.simplebar-scrollbar.simplebar-visible::before {
		opacity: 1 !important;
	}
	.simplebar-track {
		right: -2px !important;
	}
	.counrty-name:nth-child(2n) {
		text-align: unset;
		justify-content: unset;
	}
}

@media (max-width: 768.98px) {
	.retailers-online ul {
		column-count: 1 !important;
	}
}

.popup-details h3 {
	font-size: 16px;
	line-height: 24px;
	color: #030209;
	letter-spacing: -0.32px;
	font-weight: 700;
	margin-bottom: 0;
}

.popup-details address {
	margin-bottom: -5px;
}

.mapboxgl-popup-close-button {
	display: none;
}

.pop-up-website a {
	color: #008344 !important;
	margin-top: 5px;
	display: inline-block;
	letter-spacing: -0.32px;
	font-size: 13px;
	line-height: 24px;
}

.pop-up-website a:hover {
	color: #008344 !important;
}
body .steps-video .embed-container {
	position: unset;
}
#mc_embed_signup div.mce_inline_error {
	color: #eb2344 !important;
	font-size: .8em !important;
	margin: unset !important;
	padding: unset !important;
	background-color: unset !important;
	font-weight: unset !important;
}
.input-group {
	flex-direction: column;
}