/* ==========================================================================
   CAROUSEL PRODUCT
   ========================================================================== */
body {
    .content-carousel {
        width: 100%;
        display: block;
        margin: 0 auto;

        .owl-carousel {
            width: calc(100% - 76px);
            float: right;

            div {
                width: 100%;
            }

            .owl-item {
                min-height: 261px; // 368px;
                display: flex;
                align-items: center;
                background: black;
            }

            .owl-controls .owl-dot {
                background-size: cover;
                margin-top: 10px;
            }

            .owl-dots {
                position: absolute;
                top: 0;
                left: -90px;
                width: 76px;
                height: 76px;

                .owl-dot {
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    opacity: 0.5;
                    margin-right: 14px;
                    margin-bottom: 14px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &.active {
                        opacity: 1;
                    }
                }
            }
        }
    }

}

/* ==========================================================================
   Media Queries
   ========================================================================== */

@media (max-width: 575.98px) {
    body {
        .content-carousel {
            width: 100%;
            .owl-carousel {
                width: 100%;
                float: none;

                .owl-item {
                    min-height: 345px;
                }

                .owl-dots {
                    position: relative;
                    left: 0;
                    display: flex;

                    .owl-dot {
                        flex: 1 0 auto;
                        margin-top: 14px;

                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    body {
        .content-carousel {
            width: 330px;
            .owl-carousel {
                width: 100%;
                float: none;

                .owl-item {
                    min-height: 330px;
                }

                .owl-dots {
                    position: relative;
                    left: 0;
                    display: flex;

                    .owl-dot {
                        flex: 1 0 auto;
                        margin-top: 8px;
                        margin-right: 8px;

                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}
