/* ==========================================================================
   COMPONENT DEMOS
   ========================================================================== */

   body {
       &.component-demos {
            .breadcrumb {
               background: none;
               padding: 0
           }
       }
   }
