/* ==========================================================================
   HOW TO STYLES
   ========================================================================== */

body .how-to-page {
    padding-bottom: 0 !important;

    .container-fluid {

        &.video {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .title-section {
        @include font-size(20, 4vw, 24);
        margin-bottom: .5rem;
    }

    // .title-page {
    //     @include font-size(40, 4vw, 50);
    //     font-weight: 100;
    // }

    .tabs-how-to {

        .nav-tabs {
            border: none !important;
        }

        .nav-item {
            a {
                color: $dark;

                &.active {
                    border-color: transparent transparent var(--color-accent-5);
                    background-color: var(--color-accent-5);
                }
            }
        }

        .container-fluid {
            background-color: var(--color-accent-5);

            &.how-to-container {
                .step {
                    content-visibility: auto;
                    contain-intrinsic-size: 1px 5000px;

                    &.item {
                        border-top: 1px solid var(--color-accent-6--lighten-p60);
                        padding-top: 4rem;
                        margin-top: 2rem;

                        &:first-of-type {
                            border: none;
                            padding: 0;
                            margin: 0;
                        }

                        &:last-of-type {
                            margin-bottom: 2rem;

                            .step-indicator {
                                display: none;
                            }
                        }

                        .step-heading {
                            p {
                                color: var(--color-accent-6--lighten-p30);
                                @include font-size(20, 4vw, 24);
                                font-weight: 100;
                                margin-bottom: 0;

                                span {
                                    color: var(--color-accent-8);
                                }
                            }

                            h3 {
                                @include font-size(40, 4vw, 50);
                                font-weight: 100;
                                margin-bottom: 1.5rem;
                            }
                        }

                        .embed-container {
                            position: sticky;
                            top: 45px;
                        }

                        .step-indicator {
                            margin-top: 1.5rem;
                            text-align: right;
                            display: flex;
                            justify-content: flex-end;

                            a {
                                @include font-size(20, 4vw, 24);
                                font-weight: 100;
                                color: var(--color-accent-6--lighten-p30);
                                display: flex;
                                flex-direction: column;
                                justify-content: space-around;
                                align-items: center;


                                &::after {
                                    margin-top: 8px;
                                    display: block;
                                    width: 25px;
                                    height: 25px;
                                    content: '';
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23d2d2d2;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-5.379 -6)'%3E%3Cpath class='a' d='M18,7.5v21'/%3E%3Cpath class='a' d='M28.5,18,18,28.5,7.5,18'/%3E%3C/g%3E%3C/svg%3E");
                                    @extend .animate;
                                }

                                #{$hover} {
                                    &::after {
                                        @extend .animate;
                                        margin-top: 3px;
                                        margin-bottom: -10px;
                                        background-position: 0 25px;
                                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%2312783a;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-5.379 -6)'%3E%3Cpath class='a' d='M18,7.5v21'/%3E%3Cpath class='a' d='M28.5,18,18,28.5,7.5,18'/%3E%3C/g%3E%3C/svg%3E");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
// MOBILES
@include media-breakpoint-down(sm) {
    body .how-to-page {

        aside.social-share ul.social-share-list.horizontal {
            justify-content: flex-start;
            padding: 0;
        }

        .tabs-how-to {
            .container-fluid {
                &.how-to-container {
                    .step {
                        &.item {
                            .embed-container {
                                position: relative;
                                top: 0;
                                margin-bottom: 1.5rem;
                            }

                            .step-indicator {
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

// TABLETS
@include media-breakpoint-only(md) {
    body .how-to-page {

        aside.social-share ul.social-share-list.horizontal {
            justify-content: flex-start;
            padding: 0;
        }

        .tabs-how-to {
            .container-fluid {
                &.how-to-container {
                    .step {
                        &.item {
                            .step-indicator {
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }
    }
}