.published-date {
    font: normal normal 300 16px/30px Hind;
    color: #606060;
    margin-bottom: 0;
}

.news-subtitle {
    font: normal normal 300 24px/30px Hind;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
}

.news-title {
    font: normal normal 300 50px/55px Hind;
    color: #030209;
    margin-bottom: 30px;
}

.news-social-wrapper {
    display: flex;
    align-items: end;

    .social-title {
        font: normal normal bold 16px/30px Hind;
        letter-spacing: -0.32px;
        color: #030209;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .social-media-icons {
        display: flex;
        margin-bottom: 28px;

        img {
            margin-left: 25px;
            cursor: pointer;
        }
    }
}

.tabs-range {
    .container-fluid {
        background-color: var(--color-accent-5);
    }

    .nav-item a.active {
        background-color: var(--color-accent-5);
        border-left-color: transparent;
        border-bottom-color: var(--color-accent-5);
        border-right-color: transparent;
        border-top-color: transparent;
        color: #000000;
    }

    .nav-item {
        .nav-link {
            color: #000 !important;
        }

        .nav-link:not(.btn):hover {
            color: #000 !important;
        }
    }

}

#stories {

    .tagged {
        font: normal normal 300 30px/55px Hind;
        letter-spacing: -0.6px;
        color: #030209;
    }

    .tags-wrapper {
        display: flex;
        margin-top: 16px;
        margin-left: -52px;

        li {
            font: normal normal 300 18px/30px Hind;
            letter-spacing: -0.36px;
            color: #030209;
            text-align: center;
            padding: 0 8px !important;
            transition: background-color 500ms ease-out;
            margin-right: 25px;
            height: 30px;
            
        }

        li::before {
            content: "";
            padding-right: 0 !important;
        }

        li:hover {
            background-color: #007C3A;
            color: #fff !important
        }

    }
}

.tags-card {

    display: flex;
    padding-bottom: 60px;

    .tags-images {
        img {
            max-width: 210px !important;
            min-height: 210px;
        }
    }

    .tags-content {
        padding-left: 30px;

        .product-header {
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            color: #030209;
            margin-bottom: 15px;
            margin-top: -5px;
        }
    }
}

body a.active:not(.btn):not(.nav-link):not(.grid-item):not(.signpost).active,
body a:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
    text-decoration: unset !important;
}

.news-tabs::after {
    content: "" !important;
}

@media (max-width: 767px) {

    body a.active:not(.btn):not(.nav-link):not(.grid-item):not(.signpost).active,
    body a:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
        background-color: #007C3A;
        color: #fff !important;
        width: 100%;
        border-radius: 4px;
    }

    .tags-card {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .tags-images {
            padding-bottom: 24px;
        }

        .tags-content {
            padding-left: 0px;
            text-align: center;

            .product-header {
                padding-bottom: 0px;
            }
        }
    }

    #stories {
        .tags-wrapper {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 0;

            li {
                margin-right: unset !important;
                width: 100%;
                text-align: center;
                padding: 0 !important;

                a {
                    width: 100%;
                }
            }
        }

    }
}

@media (min-width: 768px) and (max-width : 1080px) {
    #stories {
        .tags-wrapper {
            li {
                padding: 0 30px !important;
                margin-left: 20px !important;
            }
        }
    }
}

.social-media-icons a:not(.btn):not(.nav-link):not(.grid-item):not(.signpost):hover {
    background-color: unset;
    color: unset !important;
    width: unset !important;
    border-radius: unset !important;
}