/* ==========================================================================
   GLOBAL STYLES
   ========================================================================== */

/* ==========================================================================
   colors http://flatuicolors.com/
   ========================================================================== */



@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin animate($animate: all, $time: 0.4, $ease: ease) {
  // Passed value in seconds
  $baseTime: 0; // Set default
  $time: ($baseTime + $time); // So we can alter later if needed
  transition: #{$animate} $time + s #{$ease}; // Transition with converted seconds to milliseconds
  backface-visibility: hidden;
  -webkit-filter: blur(0);
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  }

  @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50);
  }
}


.bg-scale,
.scale-background {
  // A background image needs to be set on the element either inline or via css
  // Oh and only for desktops
  background-size: cover !important;
  overflow: hidden;

  @media (min-width: 992px) {
    will-change: transform;
    transition: all 1s !important;
    background-size: cover !important;
    background-position: center !important;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      background-size: cover;
      transform-origin: center;
      transition: transform .4s ease-in-out;
      z-index: -1;
    }

    #{$hover} {
      &::after {
        transform: scale(1.05);
      }
    }
  }
}


.scale {
  will-change: transform;
  transition: all 1s !important;

  #{$hover} {
    transform: scale(1.2);
    transition: all 1s !important;
  }
}

/* WebKit/Blink Browsers */
::selection {
  background: var(--color-accent-1);
  color: var(--color-accent-8);
}

/* Gecko Browsers */
::-moz-selection {
  background: var(--color-accent-1);
  color: var(--color-accent-8);
}


img {
  max-width: 100%;
}

.required {
  color: var(--color-accent-4);
}

.shadow {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
}

@mixin linearGradient($fromColour, $toColour, $direction) {
  // https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100
  background: linear-gradient(to $direction, $fromColour, $toColour);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.list-col {

  &-2,
  &-3,
  &-4 {
    column-gap: 40px;
  }

  &-2 {
    column-count: 2;
  }

  &-3 {
    column-count: 3;
  }

  &-4 {
    column-count: 4;
  }
}

.col-sm-0,
.col-md-0,
.col-lg-0 {
  width: unset;
}

@mixin arrow($position: before, $rotation: -45deg, $color: var(--color-accent-1)) {

  // default '>'
  @if ($position==before) {
    &::before {
      content: '';
      border: solid $color;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate($rotation);
      transition: transform .5s ease;
      margin-right: 15px;
    }
  }

  @if ($position==after) {
    &::after {
      content: '';
      border: solid $color;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate($rotation);
      transition: transform .5s ease;
      margin-right: 15px;
    }
  }
}

.animate {
  // Legacy
  @include animate;
}

html {
  scroll-behavior: smooth;
}

.round {
  border-radius: 100% !important;
}

.square {
  border-radius: 0 !important;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  .icons {
    &-dr {
      @include animate(all, 0.1, ease-out);

      #{$hover} {
        transform: scale(1.2);
        @include animate(all, 0.1, ease-out);
      }

      &.coshh {
        margin-right: 5px;
        width: 26px;
        height: 26px;
        display: inline-block;

        &.caution {
          background-image: url("../images/coshh/svg/coshh-caution.svg");
        }

        &.corrosive {
          background-image: url("../images/coshh/svg/coshh-corrosive.svg");
        }

        &.environmental-hazard {
          background-image: url("../images/coshh/svg/coshh-dangerous-for-the-environment.svg");
        }

        &.explosive {
          background-image: url("../images/coshh/svg/coshh-explosive.svg");
        }

        &.flammable {
          background-image: url("../images/coshh/svg/coshh-flammable.svg");
        }

        &.gases-under-pressure {
          background-image: url("../images/coshh/svg/coshh-gases-under-pressure.svg");
        }

        &.long-term-health-hazards {
          background-image: url("../images/coshh/svg/coshh-long-term-health-hazards.svg");
        }

        &.oxidising {
          background-image: url("../images/coshh/svg/coshh-oxidising.svg");
        }

        &.toxic {
          background-image: url("../images/coshh/svg/coshh-toxic.svg");
        }
      }

      &.transparency {
        display: inline-block;
        border: 2px solid var(--color-dark);
        width: 20px;
        height: 20px;

        &.semi-transparent {
          background: var(--color-light);
          /* Old browsers */
          background: linear-gradient(135deg, var(--color-light) 0%, var(--color-light) 46%, var(--color-dark) 46%, var(--color-dark) 52%, var(--color-light) 52%, var(--color-light) 100%);
        }

        &.semi-opaque {
          background: var(--color-light);
          /* Old browsers */
          background: linear-gradient(135deg, var(--color-dark) 0%, var(--color-dark) 50%, var(--color-light) 50%, var(--color-light) 100%);
        }

        &.opaque {
          background: var(--color-dark);
        }
      }

      &.sizes {
        display: inline-flex;
        flex-direction: column;
        width: 12px;
        height: 45px;
        background-position: center bottom;
        background-repeat: no-repeat;

        &.ink {
          &--dropper-29-5 {
            // 29.5ml
            background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='17' viewBox='0 0 5 16.764'%3E%3Cg transform='translate(-5849 152.009)'%3E%3Crect width='5' height='12' transform='translate(5849 -147.245)'/%3E%3Cpath d='M1.606,1.789a1,1,0,0,1,1.789,0l.882,1.764A1,1,0,0,1,3.382,5H1.618A1,1,0,0,1,.724,3.553Z' transform='translate(5849 -153.245)'/%3E%3C/g%3E%3C/svg%3E");

            &-outline {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='17' viewBox='0 0 5 18' style='enable-background:new 0 0 5 18;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23000000;stroke-miterlimit:10;%7D %3C/style%3E%3Cg transform='translate(-5849 153.245)'%3E%3Cg%3E%3Cpath d='M5853-146.2v10h-3v-10H5853 M5854-147.2h-5v12h5V-147.2L5854-147.2z'/%3E%3C/g%3E%3Cpath class='st0' d='M5850.6-151.5c0.2-0.5,0.8-0.7,1.3-0.4c0.2,0.1,0.4,0.3,0.4,0.4l0.9,1.8c0.2,0.5,0,1.1-0.4,1.3 c-0.1,0.1-0.3,0.1-0.4,0.1h-1.8c-0.6,0-1-0.4-1-1c0-0.2,0-0.3,0.1-0.4L5850.6-151.5z'/%3E%3C/g%3E%3C/svg%3E");
            }
          }

          &--dropper-250 {
            // 250ml
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='30' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 8 30.4' style='enable-background:new 0 0 8 30.4;' xml:space='preserve'%3E%3Cg transform='translate(-5861 165.626)'%3E%3Crect x='5861' y='-156.2' width='8' height='21'/%3E%3Cpath d='M5865.2-157.2L5865.2-157.2c-0.1,0-0.3,0-0.4,0v0h-3.8v-1.7l2.5-2.4v-2.8c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.4,0.7,1.5,1.5v2.8 l2.5,2.5v1.7H5865.2z'/%3E%3C/g%3E%3C/svg%3E%0A");

            &-outline {
              background-image: url("data:image/svg+xml,%3Csvg version='1.1' baseProfile='tiny' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8' height='30' x='0px' y='0px' viewBox='0 0 8 30.4' overflow='visible' xml:space='preserve'%3E%3Cg transform='translate(-5861 165.626)'%3E%3Cpath d='M5868-155.2v19h-6v-19H5868 M5869-156.2h-8v21h8V-156.2L5869-156.2z'/%3E%3Cpath d='M5865-165.6l0,1c0.3,0,0.5,0.2,0.5,0.5v2.8v0.4l0.3,0.3l2.2,2.2v0.2h-1.8v-0.2l-1.2,0.2c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0 l-1.1-0.1v0.1h-1.8v-0.2l2.2-2.1l0.3-0.3v-0.4l0-2.8c0-0.3,0.2-0.5,0.5-0.5V-165.6 M5865-165.6c-0.8,0-1.5,0.6-1.5,1.5v2.8 l-2.5,2.4v1.7h3.8v0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0v0h3.8v-1.7l-2.5-2.5v-2.8C5866.5-164.9,5865.8-165.6,5865-165.6 C5865-165.6,5865-165.6,5865-165.6L5865-165.6z'/%3E%3C/g%3E%3C/svg%3E");
            }
          }

          &--dropper-500 {
            // 500ml
            background-image: url("data:image/svg+xml,%3Csvg version='1.2' baseProfile='tiny' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8' height='37' x='0px' y='0px' viewBox='0 0 9 37.8' overflow='visible' xml:space='preserve'%3E%3Cg id='Layer_1' display='none'%3E%3Cg transform='translate(-5875.5 172.5)' display='inline'%3E%3Crect x='5876' y='-162.2' fill='%23FFFFFF' stroke='%23000000' stroke-miterlimit='10' width='8' height='27'/%3E%3Cpath fill='%23FFFFFF' stroke='%23000000' stroke-miterlimit='10' d='M5880.2-164.6L5880.2-164.6l-0.3,0h-0.2l0,0h-3.8v-1.4l2.5-2.1 v-2.4c0-0.8,0.7-1.4,1.5-1.4c0.8,0,1.5,0.6,1.5,1.4v2.3l2.5,2.2v1.5H5880.2z'/%3E%3C/g%3E%3C/g%3E%3Cg id='Layer_2'%3E%3Cg transform='translate(-5875.5 172.5)'%3E%3Crect x='5876' y='-162.2' stroke='%23000000' stroke-miterlimit='10' width='8' height='27'/%3E%3Cpath stroke='%23000000' stroke-miterlimit='10' d='M5880.2-164.6L5880.2-164.6l-0.3,0h-0.2l0,0h-3.8v-1.4l2.5-2.1v-2.4 c0-0.8,0.7-1.4,1.5-1.4c0.8,0,1.5,0.6,1.5,1.4v2.3l2.5,2.2v1.5H5880.2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");

            &-outline {
              background-image: url("data:image/svg+xml,%3Csvg version='1.1' baseProfile='tiny' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8' height='37' x='0px' y='0px' viewBox='0 0 9 37.8' overflow='visible' xml:space='preserve'%3E%3Cg transform='translate(-5875.5 172.5)'%3E%3Crect x='5876' y='-162.2' fill='none' stroke='%23000000' stroke-miterlimit='10' width='8' height='27'/%3E%3Cpath fill='none' stroke='%23000000' stroke-miterlimit='10' d='M5880.2-164.6L5880.2-164.6l-0.3,0h-0.2l0,0h-3.8v-1.4l2.5-2.1 v-2.4c0-0.8,0.7-1.4,1.5-1.4c0.8,0,1.5,0.6,1.5,1.4v2.3l2.5,2.2v1.5H5880.2z'/%3E%3C/g%3E%3C/svg%3E");
            }
          }
        }

        &.paint {
          &--tube-15 {
            // 15ml
            background-image: url("data:image/svg+xml,%3Csvg version='1.2' baseProfile='tiny' width='10' height='28' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 9.8 27.8' overflow='visible' xml:space='preserve'%3E%3Cg transform='translate(-6030.019 105.806)'%3E%3Crect x='6033' y='-81' width='4' height='3'/%3E%3Cpath d='M6033-83.4h4l2.8-22.5h-9.8L6033-83.4z'/%3E%3C/g%3E%3C/svg%3E");

            &-outline {
              background-image: url("data:image/svg+xml,%3Csvg version='1.2' baseProfile='tiny' width='10' height='28' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 9.8 27.8' overflow='visible' xml:space='preserve'%3E%3Cg id='Layer_1' display='none'%3E%3Cg transform='translate(-6030.019 105.806)' display='inline'%3E%3Crect x='6033' y='-81' width='4' height='3'/%3E%3Cpath d='M6033-83.4h4l2.8-22.5h-9.8L6033-83.4z'/%3E%3C/g%3E%3C/g%3E%3Cg id='Layer_2'%3E%3Cg transform='translate(-6030.019 105.806)'%3E%3Crect x='6033' y='-81' fill='none' stroke='%23000000' stroke-miterlimit='10' width='4' height='2.5'/%3E%3Cpath fill='none' stroke='%23000000' stroke-miterlimit='10' d='M6033-83.3h4l2.2-21.9h-8.7L6033-83.3z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }
          }

          &--bottle-half-pans {
            // Half Pans
            background-image: url("data:image/svg+xml,%3Csvg version='1.2' baseProfile='tiny' width='10' height='18' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 9 18' overflow='visible' xml:space='preserve'%3E%3Cg transform='translate(-6073 96)'%3E%3Crect x='6073' y='-96' width='9' height='8'/%3E%3Crect x='6073' y='-86' width='9' height='8'/%3E%3C/g%3E%3C/svg%3E%0A");

            &-outline {
              background-image: url("data:image/svg+xml,%3Csvg version='1.2' baseProfile='tiny' width='10' height='18' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 9 18' overflow='visible' xml:space='preserve'%3E%3Cg id='Layer_1' display='none'%3E%3Cg transform='translate(-6073 96)' display='inline'%3E%3Crect x='6073' y='-96' width='9' height='8'/%3E%3Crect x='6073' y='-86' width='9' height='8'/%3E%3C/g%3E%3C/g%3E%3Cg id='Layer_1_copy'%3E%3Cg%3E%3Cg transform='translate(-6073 96)'%3E%3Crect x='6073.5' y='-95.5' fill='none' stroke='%23000000' stroke-miterlimit='10' width='8' height='7'/%3E%3C/g%3E%3Crect x='0.5' y='10.5' fill='none' stroke='%23000000' stroke-miterlimit='10' width='8' height='7'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }
          }
        }

      }
    }
  }

  .main-content {
    padding-top: 4.7rem;
  }

  .main-content>div:not(.brand-page) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .btn,
  .nav-link {
    border-radius: 0 !important;
  }

  /* Ripple effect */
  .btn,
  .ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, var(--color-light) 10%, transparent 10.01%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10, 10);
      opacity: 0;
      transition: transform .5s, opacity 1s;
    }

    &:active:after {
      transform: scale(0, 0);
      opacity: .2;
      transition: 0s;
    }
  }


  .flag-icon {
    overflow: hidden;
    text-indent: -9999px;
  }

  .social-icons {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline;

      a {
        @include font-size(18, 4vw, 22);
      }
    }
  }

  a:not(.btn):not(.nav-link):not(.grid-item):not(.signpost) {

    &:hover,
    // &:focus,
    // &:active,
    &.active {
      color: var(--color-link-hover) !important;
      text-decoration: underline !important;
    }
  }

  a:not(.btn) {

    &:hover,
    &.active {
      color: var(--color-link-hover) !important;
    }
  }

  #nav-main .menu a {

    &:hover,
    &.active {
      text-decoration: none !important;
    }
  }


  button:focus {
    outline: none;
  }

  .btn {

    &-green {
      background: var(--color-accent-8);
      border: var(--color-accent-8--darken-p10);
      color: var(--color-accent-7);

      #{$hover} {
        background: var(--color-accent-8--darken-p10);;
      }
    }

    &-white {
      background: var(--color-accent-7);
      border: var(--color-accent-7--darken-p10);
      color: var(--color-dark);

      #{$hover} {
        background: var(--color-accent-7--darken-p10);
      }
    }

    &-dark {
      background-color: var(--color-dark);
      border-color: var(--color-dark);

      #{$hover} {
        background: var(--color-accent-8);
        border-color: var(--color-accent-8--darken-p10);;
      }
    }
  }

  .instagram-feed {
    h4 {
      a {
        color: var(--color-dark);
        display: flex;
        align-items: center;
      }
    }

    a {
      &:first-of-type img {
        margin-left: 0 !important;
      }

      &:last-of-type img {
        margin-right: 0 !important;
      }
    }
  }

  aside {

    &.config-inspire {
      display: none;
      position: fixed;
      right: 1.5rem;
      top: 7rem;
      z-index: 1;

      a {
        @include font-size(20, 4vw, 24);
        @extend .shadow;
        margin-bottom: 10px;
        border-radius: 50% !important;
        padding: 8px 10px 5px;

        #{$hover} {
          color: var(--color-accent-7);
          background-color: var(--color-accent-8);
        }
      }
    }


  }

  #qc-cmp-vendor-button {
    display: none;
  }

  .qc-cmp-persistent-link,
  .qc-cmp-toggle-on {
    border-color: var(--color-dark);
    background-color: var(--color-dark) !important;
  }

  .qc-cmp-toggle-off {
    border-color: #ccc;
    background-color: #ccc;
  }
}


/* ==========================================================================
   Media Queries
   ========================================================================== */