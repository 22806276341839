.our-values {
    .our-values-title {
        color: #fff;
        letter-spacing: -0.8px;
        font-size: 40px;
        line-height: 40px;
        font-weight: bold;
        position: relative;
        margin-top: 35px;
        margin-bottom: 12px;
        margin-left: -14px;
    }

    .our-values-title::before {
        background-color: #ff0089;
        content: "";
        height: 47px;
        width: 250px;
        position: absolute;
        z-index: -1;
        left: -10px;
        top: -5px;
        transform: rotate(-2deg);
        -webkit-transform: rotate(-2deg);
        -moz-transform: rotate(-2deg);
        -ms-transform: rotate(-2deg);
        -o-transform: rotate(-2deg);
    }

    .our-values-subtitle > p {
        font-size: 30px;
        line-height: 35px;
        color: #07060C;
        margin-bottom: 20px;
        margin-top: 10px;
        margin-left: -16px;
    }

    .our-values-arrow {
        position: relative;
        right: -18%;
        top: 60px;
        cursor: pointer;
        z-index: 9;
    }
    .our-values-desc p {
        margin-right: -15px !important;
        margin-left: -15px !important;
    }
}
@media (max-width: 767px){
    .our-values-title {
        margin-left: -5px !important;
    }
}
@media (max-width:990px) {
    .our-vision {
        position: relative;
        .our-values-arrow {
            position: absolute;
            left: 15px;
            z-index: 9;
            bottom: -80px;
            cursor: pointer;
        }
        .our-values-title {
            margin-left: -5px;
        }
    }
}
@media (min-width:991px) {
    .our-vision {
        position: relative;
        .our-values-title {
            margin-left: -15px;
        }
        .our-values-arrow {
            position: absolute;
            left: 18%;
            z-index: 9;
            bottom: -70px;
            cursor: pointer;
        }
        
    }
    
}

@media (min-width:768px) and (max-width:990px) {
    .our-vision .our-vision-top-image {
        margin-top: -57px;
    }
    .paintload-artist-top-image {
        margin-top: -57px;
    }
}