@media (max-width: 767px) {
    .materials-kit-container {
        background: #F5F5F5 0% 0% no-repeat padding-box;
    }
}

.step-guide-md {
    div:nth-of-type(1) {
        img {
            width: 85%;
        }
    }

    div:nth-of-type(2) {
        .step-pattern {
            text-align: left;

            font: {
                family: Hind;
                size: 28px;
                weight: 300;
                style: normal;
            }

            ;
            letter-spacing: -0.56px;

        }

        .step-next {
            margin-top: 2rem;

        }
    }

}

.step-guide-sm {
    div:nth-of-type(1) {
        h1 {
            text-align: center;

            font: {
                family: Hind;
                size: 24px;
                weight: bold;
                style: normal;
            }

            letter-spacing: -0.48px;

        }
    }

    div:nth-of-type(2) {
        .step-num-sm {
            font: {
                family: Hind;
                size: 24px;
                weight: 300;
                style: normal;
            }

            letter-spacing: -0.48px;
            max-width: 175px;
            text-align: center;
        }

    }

    .step-p-sm {

        p,
        div {
            font: {
                family: Hind;
                size: 16px;
                weight: 300;
                style: normal;
            }

            letter-spacing: -0.32px;

        }
    }
}

.how-to-back {
    a {
        i {
            border: solid black;
            border-width: 0 1px 1px 0px;
            display: inline-block;
            padding: 4px;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
        }

        span {
            font: {
                family: Hind;
                size: 18px;
                weight: 300;
                style: normal;
            }

            ;
            line-height: 30px;
            letter-spacing: -0.36px;
            color: #030209;
            opacity: 1;
        }

    }

}

.how-to-header {
    font: {
        family: Hind;
        size: 24px;
        weight: 300;
        style: normal;
    }

    letter-spacing: -.48px;
    line-height: 30px;

}

.please-add {
    font-size: 24px;
    letter-spacing: -.48px;
    font-weight: 300;
    line-height: 30px;
}

.how-to-paragraph {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.how-to-paragraph p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.how-to-paragraph:last-child {
    padding-bottom: 32px;
}

.how-to-page {

    // .title-page {
    //     font-weight: 100;
    //     font-size: 40px;
    //     line-height: 46px;
    //     letter-spacing: -0.8px;
    // }

    .btn-materials-steps {
        position: fixed;
        z-index: 1;
        bottom: 0px;
        background-color: #fff;
        width: 100%;
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;

        a {
            text-transform: uppercase;
        }

        a:nth-of-type(1) {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 39px !important;
            margin-right: 12px;

        }

        a:nth-of-type(2) {
            width: 50%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 39px !important;

        }
    }

    .fullwidth-header {
        margin-top: -35px;
        margin-bottom: 0;

        .container {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }
    }
}

.how-to-container {
    #how-to-content {
        #what-you-need {
            background-color: #F5F5F5;
        }
    }
}

.what-you-need {
    background-color: var(--color-accent-5);
    margin-bottom: 48px;
}

body .main-content>.how-to-page:not(.brand-page) {
    padding-top: 0px;
}

body .main-content>.how-to-back:not(.brand-page) {
    padding-bottom: 0px;
}

body .how-to-page .tabs-how-to .container-fluid {
    background-color: white !important;
}

#how-to>div:nth-of-type(2)>div.step-guide-md:nth-last-of-type(2) .step-next {
    display: none;
}

.no-gutters>[class*="col-"] {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.how-to-page a[title] {
    color: #007C3A !important;
}

.embed-container.video {
    padding-top: 35px;
}

#what-you-need h1 {
    font-size: 30px;
    letter-spacing: -0.6px;
    color: #030209;
}

.product-header {
    font-size: 22px;
    line-height: 25px;
    color: #000000;
}

@media (min-width:992px) {

    // .steps-video iframe.vp-center {
    //     align-items: flex-start !important;
    // }
    #what-you-need h1 {
        padding-bottom: 48px;
        margin-bottom: 0;
        // padding-top: 15px;
    }

    .embed-container.video {
        padding-top: 0px;
    }

    .products-carousel-item:last-child {
        padding-bottom: 15px;
    }

    .how-to-header {
        font-size: 1.75rem;
        letter-spacing: -.56px;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    .please-add {
        font-size: 1.75rem;
        letter-spacing: -.56px;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    .how-to-paragraph {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
    }

    .how-to-page {
        .fullwidth-header {
            margin-top: 1rem;
            margin-bottom: 26px;
        }
    }

    .how-to-paragraph:last-child {
        padding-bottom: unset;
    }

    .no-gutters>[class*="col-"] {
        margin-right: 10px !important;
    }

    .d-md-flex.justify-content-between.step-guide {
        padding-bottom: 0rem !important;
        padding-top: 6rem !important;
    }

    #step-1 .d-md-flex.justify-content-between.step-guide {
        padding-top: 40px !important;
    }
}