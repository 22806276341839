.partner-with-us {
	background-color: #000;
}

.partner-with-us-title {
	font-size: 40px;
	line-height: 40px;
	letter-spacing: -.8px;
	margin-top: 64px;
	color: #fff;
	font-weight: 700;
	position: relative;
	z-index: 1;
}

.partner-with-us-title::before {
	content: "";
	background-color: #00bea8;
	position: absolute;
	width: 345px;
	z-index: -1;
	transform: rotate(1deg);
	height: 58px;
	left: -10px;
	top: -10px;
}

.partner-with-us-desc>p {
	font-size: 16px;
	line-height: 26px;
	color: #fff !important;
	max-width: 350px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 28px;
}

.partner-with-us-link a {
	background-color: #fff;
	color: #000000;
	padding: 8px 31px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 75px;
	max-width: 175px;
	margin-top: 16px;
	display: flex;
}

@media (min-width:991px) {
	.partner-with-us-title {

		margin-left: unset;
		margin-right: unset;
	}

	.partner-with-us-desc>p {
		margin-bottom: 64px;
		margin-left: unset;
		margin-right: unset;
		margin-top: 28px;
		color: #fff !important;
	}

	.partner-with-us-link a {
		background-color: #00bea8;
		font-size: 40px;
		text-align: center;
		line-height: 40px;
		letter-spacing: -.8px;
		margin-top: 140px;
		padding: 8px;
		max-width: 400px;
		display: flex;
		align-items: center;
		transform: rotate(1deg);
		color: #fff;
		font-weight: bold;
		text-transform: uppercase;
		transition: all ease 500ms;
	}
}

@media (max-width:990px) {
	.partner-with-us-desc>p {
		font-size: 16px;
		line-height: 26px;
		color: #fff !important;
		margin-top: 28px;
		margin-left: unset;
		margin-right: unset;
		max-width: unset;
	}

	.partner-with-us-title {
		left: 10px;
	}
}