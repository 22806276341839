.campaign-hero-section {
    background-color: #0F4288;
    position: relative;
    overflow: hidden;
    padding-bottom: 30px;

    .button-images {
        display: flex;
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.btn-container {
    .hero-button {
        background-color: #02ADEF;
        border-radius: 4px;
        margin-top: 12px;
        display: inline-block;
        color: #fff;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 7px;
        padding-bottom: 7px;
        width: auto;
        text-align: center;
    }
}

.button-images {
    .img-container {
        margin-top: 20px;
    }
}

.titles-container {
    margin-top: 40px;
    margin-bottom: 55px;

    .simply-title {
        font-size: 47px;
        color: #fff;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.96px;
    }

    .simply-sub-title p {
        font-size: 24px;
        color: #fff;
        margin-top: 20px;
        letter-spacing: -0.48px;
        line-height: 30px;
    }
}

.img-flip {
    display: flex;

    .flip-image {
        max-width: 56%;
        position: relative;
        //  right: -70px;
    }

    .simply-description p {
        padding-left: 0px;
        font-size: 16px;
        color: #ffff;
        line-height: 26px;
        min-width: 236px;
    }
}

.arrow-btn {
    position: absolute;
    bottom: -9px;
    left: 33%;
    max-width: 34%;
}

.desktop-img {
    display: none;
}

#campaign-hero .hero-button:hover {
    color: #fff !important;
    background-color: #007a39 !important;
}

@media (min-width:991px) {
    .card-height {
        position: relative;
    }

    .card-btn {
        position: absolute !important;
        max-width: 350px !important;
        bottom: -35px !important;
    }

    // body .main-content .title-page {
    //     margin-bottom: 0px !important;
    // }

    body .main-content .title-section {
        margin-bottom: 10px !important;
    }

    .mobile-img {
        display: none;
    }

    .campaign-hero-section {
        overflow: unset;
    }

    .button-images {
        display: block !important;
        padding-bottom: 160px;
    }

    .btn-container {
        margin-top: 41px;

        .hero-button {
            margin-right: 10px;
            padding: 7px;
            min-width: 135px;
        }
    }

    .titles-container {
        .simply-title {
            font-size: 60px;
            line-height: 60px;
            letter-spacing: -1.2px;
        }

        .simply-sub-title p {
            max-width: 540px;
            font-size: 35px;
            letter-spacing: -0.7px;
            line-height: 40px;
        }

        margin-bottom: 80px;
    }

    .img-flip {
        .simply-description p {
            max-width: 477px;
        }

        .flip-image {
            position: absolute;
            top: -340px;
            max-width: 64%;
            right: -305px;
            //  z-index: 99999;
        }
    }

    .arrow-btn {
        left: 42%;
        bottom: -10px;
    }

    .arrow-btn img {
        max-width: 90%;
    }

    body {
        .content-carousel {
            .owl-carousel {
                .owl-dots {
                    .owl-dot {
                        width: 100% !important;
                        min-width: 70px !important;
                        max-height: 70px !important;
                        margin-right: 8px !important;
                    }
                }
            }
        }
    }

    body {
        .content-carousel {
            .owl-carousel {
                .owl-dots {
                    position: unset !important;
                    left: auto !important;
                    width: auto !important;
                    height: auto !important;
                    display: flex !important;
                    justify-content: space-between !important;
                }
            }
        }
    }

    body {
        .content-carousel {
            .owl-carousel {
                width: 100% !important;
                float: none !important;
            }
        }
    }

    body {
        .content-carousel {
            .owl-carousel {
                .owl-dots {
                    .owl-dot {
                        &:last-child {
                            max-width: 60px !important;
                            min-width: unset !important;
                            max-height: 100% !important;
                            height: 60px !important;
                            margin-top: 4px !important;
                        }
                    }
                }
            }
        }
    }
}

.nav-carousel.owl-theme .owl-nav {
    position: absolute;
    top: 15%;
    margin: 0;
    width: 100%;
}

.nav-carousel .owl-prev,
.nav-carousel .owl-next {
    width: auto;
    padding: 0px !important;
    height: auto;
    font-size: 50px !important;
}

.nav-carousel .owl-prev:hover,
.nav-carousel .owl-next:hover {
    background-color: transparent !important;
    color: #4f63cf !important;
}

.nav-carousel .owl-next {
    position: absolute;
    right: -30px;
}

.nav-carousel .owl-prev {
    position: absolute;
    left: -30px;
}

.main-carousel .owl-dots {
    display: none !important;
}

.owl-carousel .owl-item .item {
    cursor: pointer;
}

* {
    content-visibility: unset !important;
}

@media(max-width:767px) {
    .nav-carousel.owl-theme .owl-nav {
        top: 0px;
    }

    .nav-carousel .owl-next {
        right: -18px;
    }

    .nav-carousel .owl-prev {
        left: -18px;
    }
}

.tabs-for-forms .tab-container {
    display: none;
}

.tabs-for-forms .tab-container.show {
    display: block;
}

.tabs-for-forms .tab-link {
    background: #fff;
    border: 0;
    font-size: 18px;
    line-height: 1.2;
    padding: 16px;
    border-radius: 1rem;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin-left: 0;
    margin-right: 0;
    transition: all ease 500ms;
    flex: 0 0 45%;
    margin-top: 24px;
}

.form_submit {
    padding: 16px 40px;
    margin-top: 16px;
    background: transparent;
    border: 1px solid #d5d5d5;
    border-radius: 16px;
    transition: all ease 600ms;
}

.form_submit:hover {
    color: #fff;
    background: #000;
}

.tabs-for-forms .tab-link:hover,
.tabs-for-forms .tab-link.active {
    color: #fff;
    background: #000;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.tabs-for-forms .tabs-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 42px;
    margin-bottom: 24px;
    border-bottom: 1px solid #dcdcdc;
}

.wcoform label {
    display: block;
    margin-bottom: 8px;
}

.wcoform input,
.wcoform input:hover,
.wcoform textarea,
.wcoform select {
    width: 100%;
    padding: 16px;
    border: 1px solid #d5d5d5;
    border-radius: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background: #fff;
    outline: none;
}

.wcoform input:focus {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.wcoform>div {
    margin-bottom: 16px;
}

.wcoform textarea {
    height: 200px;
}

.error-msg {
    color: #f00;
    margin-top: 16px;
}

.error input,
.error select,
.error textarea {
    border-color: #f00 !important;
}

.wcoform input[type="radio"],
.wcoform input[type="radio"]:hover,
.wcoform input[type="radio"]:focus {
    width: auto;
    background: transparent;
    box-shadow: none;
}

.wcoform select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.select-wrapper {
    position: relative;
}

#Retailer .select-wrapper label::after {
    content: "";
    width: 12px;
    height: 12px;
    position: absolute;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    top: 45px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    right: 16px;
    margin-top: 0px;
}

#Artists .select-wrapper label::after,
#LUKAS-Retailer .select-wrapper label::after,
#LUKAS-Artists .select-wrapper label::after {
    content: "";
    width: 12px;
    height: 12px;
    position: absolute;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    top: 45px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    right: 16px;
    margin-top: 8px;
}

.form_submit {
    padding: 16px 40px;
    margin-top: 16px;
    background: #007a39;
    border: 1px solid #007a39;
    border-radius: 16px;
    -webkit-transition: all .6s ease;
    transition: all .6s ease;
    color: #fff;
}

.form_submit:hover {
    color: #fff;
    background: #004721
}

@media (max-width: 991px) {
    .tabs-for-forms .tab-link {
        flex: 0 0 100%;
        margin: 16px 0;
        font-size: 14px;
    }
}

//Header
.related-items.useful-products h3 {
    font-size: 16px;
    /* font-size: clamp(12px,4vw,16px); */
    /* line-height: clamp(16px,4vw,20px); */
    /* font-weight: 800; */
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0px;
    font-weight: normal;
    font-family: Hind;
}

//content
.product-content {
    font-weight: 300;
    opacity: 1px;
    line-height: 25px;
}

//button
.product-link {
    /* background-color: red; */
    color: #FFFFFF;
    border-radius: 1px !important;
    font-family: Hind;
    font-size: 15px;
    line-height: 30px;
    text-transform: uppercase !important;
}