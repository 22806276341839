/* ==========================================================================
   SOCIAL LINKS
   ========================================================================== */

body {

  section.social-share {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6rem 3rem 5rem;

    h4 {
      @include font-size(30, 4vw, 40);
      font-weight: 900;
      margin: 0;

      span {
        color: var(--color-accent-8);
      }
    }

    aside.social-share {
      ul.social-share-list {
        margin: 0;
        padding: 0;

        li {
          margin-left: 10px;

          &.label {
            display: none;
          }

          a {
            @extend .shadow;
            border-radius: 50% !important;

            .hydrated {
              margin: 7px 2px -3px;
            }
          }
        }
      }
    }
  }

  aside {
    &.social-share {
      ul.social-share-list {

        &.vertical {
          position: fixed;
          right: 1.5rem;
          bottom: 1.5rem;
          z-index: 1;

          li {
            &.label {
              display: none;
            }

            a {
              @extend .shadow;
              margin-bottom: 10px;
              border-radius: 50% !important;
              padding: 8px 10px 3px;

              #{$hover} {
                color: var(--color-accent-7) !important;
                background-color: var(--color-accent-8);
              }
            }
          }
        }

        &.horizontal {
          display: flex;
          align-items: baseline;
          justify-content: flex-end;

          li {
            a {
              font-size: 20px;

              &.btn {
                background: transparent;
              }

              #{$hover} {
                color: var(--color-accent-8);
                background-color: var(--color-accent-7);
              }

            }
          }
        }

        &.round {
          display: flex;
          justify-content: space-between;
          padding: 0;

          li {
            a {
              @extend .round;
              display: flex;
              flex: 1 1 auto;
              height: 42px;
              align-items: center;
            }
          }
        }

        li {
          list-style: none;

          &.label {
            font-weight: 600;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media (max-width: 575.98px) {
  body {

    section.social-share {
      flex-direction: column;

      aside {
        margin-top: 3rem;
      }
    }
  }
}

@media (max-width: 768.98px) {}

@media (max-width: 768.98px) {}